import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SideMenu from "../../../components/menus/m-side";
import { useNavHeight } from "../../../__utils/context/navheightprovider";
import EquipePedagogique from "../../../components/pages/enseignement/c-equipepedagogique";
import EcoleTennis from "../../../components/pages/enseignement/c-ecoletennis";
import TennisAdultes from "../../../components/pages/enseignement/c-tennisadlute";
import StageTennis from "../../../components/pages/enseignement/c-stagetennis";
import TennisSante from "../../../components/pages/enseignement/c-tennissante";
import Pickleball from "../../../components/pages/enseignement/c-pickleball";

const Enseignement = () => {
  const { navbarHeight } = useNavHeight();

  return (
    <main role="main" className="mc-fond-courts-couverts">
      <Container fluid className="gx-0">
        <Row
          className="gx-0"
          data-bs-spy="scroll"
          data-bs-target="#sidemenu"
          data-bs-offset="0"
          data-bs-smooth-scroll="true"
        >
          <Col
            md={3}
            className="gx-0"
            style={{
              position: "sticky",
              top: navbarHeight,
              zIndex: "1000",
            }}
          >
            <SideMenu />
          </Col>
          <Col md={9} className="gx-0">
            <div>
              <EquipePedagogique />
              <EcoleTennis />
              <TennisAdultes />
              <StageTennis />
              <TennisSante />
              <Pickleball />
            </div>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default Enseignement;
