import React, { useEffect, useRef, useState } from "react";
// import emailjs from "@emailjs/browser";
import { useReactToPrint } from "react-to-print";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import * as formik from "formik";
import commandeSchema from "../../../__params/validationschema/commandes/cdesSchema";
import commandesInitValues from "../../../__params/validationschema/commandes/cdesInitValues";
import CommandePayment from "./c-cde-modal";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
// import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import FaIcons from "../../../assets/icons/faicons";
import { IconContext } from "react-icons";
import tabTarifsProduits from "../../../__params/tab-pages/ventes/tab-tarifsProduits";
import tabInitProduits from "../../../__params/tab-pages/ventes/tab-initProduits";
import tabColors from "../../../__params/tab-config/tab-colors";
import resetTable from "../../../__utils/tools/reset-table";
import logoTCF from "../../../assets/img/img-header/logoTCF1.png";
import BtnReset from "../../../__utils/tools/btn-reset";

export const CommandeProduits = () => {
  const { Formik } = formik;

  const formRef = useRef();
  const printRef = useRef();

  /*********************** State pour afficher les tarifs produits ************************/
  const [newTarifs, setNewTarifs] = useState(tabInitProduits);
  /*********************** State pour afficher le toast sur l'envoi de la cde *************/
  const [messageCde, setMessageCde] = useState(false);
  const [showOrder, setShowOrder] = useState(false);

  let expiredDateStatus = false;

  /*********************** Function to show helloasso order payment procese **************/
  const handleOrder = () => {
    setShowOrder(true);
  };

  /*********************** Récupération de la fammile valide ******************************/
  let tarifs = tabInitProduits;
  let newDateNumber = Date.parse(new Date());
  let numberOfMillisecondPerDay = 1000 * 60 * 60 * 24;
  let dateOfTheDay = Math.floor(newDateNumber / numberOfMillisecondPerDay);

  tabTarifsProduits.forEach((famille) => {
    let dateValidityNumber = Date.parse(famille.validityDate);
    let dateOfValidity = Math.floor(
      dateValidityNumber / numberOfMillisecondPerDay
    );

    if (famille.isValid) {
      /*********************** Vérification de la date de validité des commandes ********/
      dateOfTheDay > dateOfValidity
        ? (expiredDateStatus = true)
        : (expiredDateStatus = false);
      /*********************** Copie des données initiales dans le state newTarif ********/
      tarifs = famille.familles;
    }
  });

  useEffect(() => {
    setNewTarifs(tarifs);
  }, [tarifs]);

  /*********************** Variables de calcul des totaux ************************************************/
  let totalFamille = 0;
  let totalCde = 0;
  let btnResetStatus = false;

  /*********************** Ajout des propriétés de calcul des totaux et d'affectation de couleur bg ******/
  newTarifs.forEach((famille, i) => {
    famille.bg = famille.bg ?? tabColors[i].rgb;
    famille.totalFamille = famille.totalFamille ?? 0;
    totalFamille = 0;
    famille.totalFamille = totalFamille;
    /*********************** Fonction de calcul du total produits ******/
    famille.produits.forEach((produit, k) => {
      famille.produits[k].qty = famille.produits[k].qty ?? 0;
      famille.produits[k].totalLine = famille.produits[k].totalLine ?? 0;
      if (produit.totalLine) {
        totalFamille += produit.totalLine;
        famille.totalFamille = totalFamille;
      }
    });
    /*********************** Calcul du total commande ******/
    totalCde = totalCde + totalFamille;
  });

  /*** Mise à jour de l'indicateur permettant d'afficher le bouton RAZ table *****************************/
  totalCde === 0 ? (btnResetStatus = false) : (btnResetStatus = true);

  /*********************** Fonction de remise à zéro du tableau après le clic ****************************/
  const handleRemiseAZero = (values) => {
    // console.log("RAZ ..........");
    setNewTarifs(resetTable(newTarifs));
    totalCde = 0;

    // /********************* Remise à zéro des VALUES de FORMIK *******************************************/
    values.userName = "";
    values.userTel = "";
    values.userMail = "";
    values.userTotalCde = 0;
    delete values.userFamilles;
    values.userFamilles = [{}];
    values.userFamilles[0].userFamId = null;
    values.userFamilles[0].userFamLibelle = "";
    values.userFamilles[0].userFamTotalFamille = 0;
    values.userFamilles[0].userFamProduits = [{}];
    values.userFamilles[0].userFamProduits[0].userProdId = null;
    values.userFamilles[0].userFamProduits[0].userProdLibelle = "";
    values.userFamilles[0].userFamProduits[0].userProdUnite = "";
    values.userFamilles[0].userFamProduits[0].userProdPU = 0;
    values.userFamilles[0].userFamProduits[0].userProdQty = 0;
    values.userFamilles[0].userFamProduits[0].userProdTotalProd = 0;
  };

  /*********************** Gestion des lignes de commande à chaque changement de qté *********************/
  const handleChangeQty = (familleId, produitId, pu, values) => (e) => {
    const updateItem = newTarifs.map((famille) => {
      famille.produits.forEach((produit) => {
        if (familleId === famille.id && produitId === produit.produitId) {
          produit.qty = Number(e.target.value);
          produit.totalLine = pu * Number(e.target.value);
          famille.totalFamille = famille.totalFamille + produit.totalLine;

          /*********************** Mise à jour des Values FORMIK *****************************************/
          const updateProduits = {
            userProdId: produit.produitId,
            userProdLibelle: produit.libelle,
            userProdUnite: produit.unite,
            userProdPU: produit.prixUnitaire,
            userProdQty: produit.qty,
            userProdTotalProd: produit.totalLine,
          };

          const updateFamilles = {
            userFamId: famille.id,
            userFamLibelle: famille.famille,
            userFamTotalFamille: famille.totalFamille,
            userFamProduits: [updateProduits],
          };

          // ** Mise à jour des propriétés de VALUES FORMIK **********************************************/
          const lengthFam = values.userFamilles.length;
          for (let idxFam = 0; idxFam < lengthFam; idxFam++) {
            // ** Si values.userFamilles.userFamId est "null" valeur initiale ****************************/
            if (values.userFamilles[idxFam].userFamId === null) {
              values.userFamilles[idxFam].userFamId = famille.id;
              values.userFamilles[idxFam].userFamLibelle = famille.famille;
              values.userFamilles[idxFam].userFamProduits = [updateProduits];
            } else {
              // ** Sinon values.userFamilles.userFamId n'est pas null, donc une famille existe **********/
              // ** Si la famille de VALUES est égale à celle saisie  dans le FORM ***********************/
              if (values.userFamilles[idxFam].userFamId === familleId) {
                // ** Lecture des produits existants *****************************************************/
                const lengthProd =
                  values.userFamilles[idxFam].userFamProduits.length;
                for (let idxProd = 0; idxProd < lengthProd; idxProd++) {
                  // ** Si values.userProdId est "null" valeur initiale **********************************/
                  if (
                    values.userFamilles[idxFam].userFamProduits[idxProd]
                      .userProdId === null
                  ) {
                    values.userFamilles[idxFam].userFamProduits[idxProd] =
                      updateProduits;
                    break; // Permet de sortir de la boucle PRODUIT
                  } else {
                    if (
                      values.userFamilles[idxFam].userFamProduits[idxProd]
                        .userProdId === produitId
                    ) {
                      // ** Si values.userProdId n'est pas "null" le produit existe **********************/
                      values.userFamilles[idxFam].userFamProduits[
                        idxProd
                      ].userProdQty = produit.qty;
                      values.userFamilles[idxFam].userFamProduits[
                        idxProd
                      ].userProdTotalProd = produit.totalLine;
                      idxFam += lengthFam; // Permet de sortir de la boucle FAMILLE
                      break; // Permet de sortir de la boucle PRODUIT
                    } else {
                      // ** Sinon le produit est différent ou n'existe pas *******************************/
                      // ** Si je suis pas à la fin de la table, le "produits" n'existe pas **************/
                      if (idxProd >= lengthProd - 1) {
                        // ** Création push du produit s'il n'existe pas *********************************/
                        values.userFamilles[idxFam].userFamProduits.push(
                          updateProduits
                        );
                        idxFam += lengthFam; // Permet de sortir de la boucle FAMILLE
                        break; // Permet de sortir de la boucle PRODUIT
                      }
                    }
                  }
                } // fin de boucle Values "produits"
              } else {
                // ** La famille est différente *********************************************************/
                // ** Si je ne suis pas à la fin de la table je refais une boucle ***********************/
                if (idxFam >= lengthFam - 1) {
                  // ** Création push de la famille si elle n'existe pas ********************************/
                  values.userFamilles.push(updateFamilles);
                  break;
                }
              }
            }
          } // fin de boucle Values "famille"

          // ** Mise à jour des totaux famille et commande de VALUES FORMIK ****************************/
          values.userTotalCde = 0;
          let famLength = values.userFamilles.length;

          for (let idxF = 0; idxF < famLength; idxF++) {
            values.userFamilles[idxF].userFamTotalFamille = 0;
            /*********************** Fonction de calcul du total produits ******************************/
            let prodLength = values.userFamilles[idxF].userFamProduits.length;

            for (let idxP = 0; idxP < prodLength; idxP++) {
              if (
                values.userFamilles[idxF].userFamProduits[idxP]
                  .userProdTotalProd === 0 &&
                prodLength > 1
              ) {
                /*********************** Suppression du produit avec un total à zéro *******************/
                values.userFamilles[idxF].userFamProduits.splice(idxP, 1);
                idxP -= 1;
                prodLength -= 1;
              } else {
                if (
                  values.userFamilles[idxF].userFamProduits[idxP]
                    .userProdTotalProd === 0 &&
                  prodLength === 1
                ) {
                  /*********************** Réinitialisation des données PRODUIT ***********************/
                  values.userFamilles[idxF].userFamProduits[idxP].userProdId =
                    null;
                  values.userFamilles[idxF].userFamProduits[
                    idxP
                  ].userProdLibelle = "";
                  values.userFamilles[idxF].userFamProduits[
                    idxP
                  ].userProdUnite = "";
                  values.userFamilles[idxF].userFamProduits[
                    idxP
                  ].userProdPU = 0;
                } else {
                  if (
                    values.userFamilles[idxF].userFamProduits[idxP]
                      .userProdTotalProd !== 0
                  ) {
                    /*********************** Calcul du total FAMILLE ***********************************/
                    values.userFamilles[idxF].userFamTotalFamille +=
                      values.userFamilles[idxF].userFamProduits[
                        idxP
                      ].userProdTotalProd;
                  }
                }
              }
            }
            if (
              values.userFamilles[idxF].userFamTotalFamille === 0 &&
              famLength > 1
            ) {
              /*********************** Suppression de la FAMILLE avec un total à zéro *******************/
              values.userFamilles.splice(idxF, 1);
              idxF -= 1;
              famLength -= 1;
            } else {
              if (
                values.userFamilles[idxF].userFamTotalFamille === 0 &&
                famLength === 1
              ) {
                /*********************** Initialisation des données famille *****************************/
                values.userFamilles[idxF].userFamId = null;
                values.userFamilles[idxF].userFamLibelle = "";
              } else {
                if (values.userFamilles[idxF].userFamTotalFamille !== 0) {
                  /*********************** Calcul du total commande *************************************/
                  values.userTotalCde +=
                    values.userFamilles[idxF].userFamTotalFamille;
                }
              }
            }
          }
        }
      }); // Fin de boucle newTarifs "produit"
      return famille;
    }); // fin du map newTarifs "famille"
    setNewTarifs(updateItem);
  };

  /*********************** Temporisation du messsage d'envoi de commande ********************************/
  useEffect(() => {
    if (messageCde) {
      setTimeout(() => {
        setMessageCde(false);
      }, 3000);
    }
  }, [messageCde]);

  /*********************** Fonction d'envoi du mail après le clic transmettre ***************************/
  // const SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  // const TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ORDER_ID;
  // const PUBLIC_ID = process.env.REACT_APP_EMAILJS_PUBLIC_ID;

  // const sendMail = (values, { setSubmitting, resetForm, errors }) => {
  //   // e.preventDefault();
  //   // emailjs
  //   //   .sendForm(SERVICE_ID, TEMPLATE_ID, formRef.current, PUBLIC_ID)
  //   //   .then(
  //   //     (result) => {
  //   setMessageCde(true);
  //   //       setSubmitting(false);
  //   resetForm();
  //   //     },
  //   //     (error) => {
  //   //       setMessageCde(false);
  //   //       SetSubmitting(false);
  //   //       console.log("Error emailjs: ", error);
  //   //     }
  //   //   );
  //   console.log("errors: ", errors);
  //   setSubmitting(false);
  //   setNewTarifs(resetTable(newTarifs));
  // };

  /*********************** Fonction d'impression de la commande après le clic ***************************/
  const handlePrint = useReactToPrint({
    documentTitle: "TCF-Calul_vente_fromage_21-12-2024",
    content: () => printRef.current,
  });

  return (
    <div>
      <Container fluid className="px-0 m-0">
        <Row className="justify-content-center">
          {expiredDateStatus && (
            <Card
              className="border border-4 border-warning bg-danger position-absolute top-25 d-flex flex-md-row align-items-center w-50 p-2"
              style={{
                zIndex: 100,
                transform: "rotate(-10deg)",
              }}
            >
              <Col xs={12} md={2} className="h-100">
                <Card.Body className="text-light text-center fw-bold p-0">
                  <Card.Text>
                    <IconContext.Provider value={{ size: "6vw" }}>
                      <FaIcons.FaExclamationTriangle />
                    </IconContext.Provider>
                  </Card.Text>
                </Card.Body>
              </Col>
              <Col xs="12" md="10">
                <Card.Body className="text-light text-center fw-bold p-0">
                  <Card.Text>
                    <span style={{ fontSize: "2.5vw" }}>
                      Date de Commande Expirée
                    </span>
                  </Card.Text>
                </Card.Body>
              </Col>
            </Card>
          )}
          <Formik
            initialValues={commandesInitValues}
            validationSchema={commandeSchema}
            // onSubmit={sendMail}
            onReset={handleRemiseAZero}
            // enableReinitialize
          >
            {({
              handleSubmit,
              handleBlur,
              handleChange,
              resetForm,
              isSubmitting,
              values,
              touched,
              errors,
            }) => (
              <Form
                ref={formRef}
                noValidate
                onSubmit={handleSubmit}
                className={expiredDateStatus && "opacity-50"}
              >
                <div ref={printRef} className="w-100">
                  <Card className="bg-transparent mt-3 border-0">
                    <Card.Title className="d-flex">
                      <Col md="3">
                        <img
                          className="img-fluid py-2"
                          src={logoTCF}
                          alt="Logo TCF ?"
                          width={70}
                        ></img>
                      </Col>
                      <Col
                        md="9"
                        className="d-flex align-items-center text-center mx-auto"
                      >
                        <p
                          className="fw-bold m-0"
                          style={{ fontSize: "2.5vw" }}
                        >
                          ESTIMATION DE LA COMMANDE
                        </p>
                      </Col>
                    </Card.Title>
                    <Card.Text className="mb-2 border border-3 border-secondary"></Card.Text>
                  </Card>

                  {/* {messageCde && (
                    <Modal
                      show={messageCde}
                      centered
                      onHide={() => setMessageCde(false)}
                      contentClassName="flex-row bg-success rounded border border-4 m-2"
                    >
                      <Modal.Header className="rounded-0 border-0">
                        <Modal.Title className="text-light">
                          <IconContext.Provider value={{ size: "2rem" }}>
                            <FaIcons.FaCheck />
                          </IconContext.Provider>
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="bg-success text-light fw-bold">
                        <p>
                          Votre commande a bien été transmise ! <br /> Merci !
                        </p>
                      </Modal.Body>
                    </Modal>
                  )} */}

                  <Card className="bg-transparent border-0 flex-row align-items-center">
                    <Card.Body className="text-center p-0">
                      <Card.Text
                        className="fst-italic text-danger fw-bold p-0"
                        style={{ fontSize: "0.8rem" }}
                      >
                        {expiredDateStatus
                          ? "La date de commande est expirée"
                          : "Saisir au moins une ligne de commande."}
                        {/* : "Saisir les coordonnées ci-dessous et au moins une ligne de commande."} */}
                      </Card.Text>
                    </Card.Body>
                    <Form.Group as={Col} md="1" className="text-end">
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-top">Reset</Tooltip>}
                      >
                        {BtnReset(resetForm)}
                      </OverlayTrigger>
                    </Form.Group>
                  </Card>

                  {/* <Row>
                    <Form.Group
                      className="my-2"
                      controlId="validationFormikUserName"
                    >
                      <InputGroup hasValidation>
                        <InputGroup.Text
                          className="border border-1 border-secondary rounded-start justify-content-center fw-bold"
                          style={{ fontSize: "0.9rem" }}
                          id="inputGroupPrepend"
                        >
                          Nom - Prénom :
                        </InputGroup.Text>
                        <Form.Control
                          style={{ fontSize: "0.9rem" }}
                          type="text"
                          placeholder="Entrez votre nom et prénom"
                          name="userName"
                          value={values.userName}
                          aria-describedby="inputGroupPrepend"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          isValid={touched.userName ?? !errors.userName}
                          isInvalid={!!errors.userName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.userName}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      md="6"
                      className="mb-2"
                      controlId="validationFormikUserMail"
                    >
                      <InputGroup hasValidation>
                        <InputGroup.Text
                          className="border border-1 border-secondary rounded-start justify-content-center fw-bold"
                          id="inputGroupPrepend"
                        >
                          @
                        </InputGroup.Text>

                        <Form.Control
                          className="border border-1 border-secondary"
                          style={{ fontSize: "0.9rem" }}
                          type="text"
                          placeholder="Entrez votre adresse email"
                          name="userMail"
                          value={values.userMail}
                          aria-describedby="inputGroupPrepend"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          isValid={touched.userMail && !errors.userMail}
                          isInvalid={!!errors.userMail}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.userMail}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      md="6"
                      className="mb-2"
                      controlId="validationFormikUserTel"
                    >
                      <InputGroup hasValidation>
                        <InputGroup.Text
                          className="border border-1 border-secondary rounded-start justify-content-center"
                          id="inputGroupPrepend"
                        >
                          <FaIcons.FaPhone />
                        </InputGroup.Text>
                        <Form.Control
                          style={{ fontSize: "0.9rem" }}
                          type="tel"
                          placeholder="Entrez votre numéro de tél"
                          aria-describedby="inputGroupPrepend"
                          name="userTel"
                          value={values.userTel}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          isValid={touched.userTel ?? !errors.userTel}
                          isInvalid={!!errors.userTel}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.userTel}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Row> */}
                  {newTarifs.map((famille) => {
                    return (
                      <Card key={famille.id} className="my-4">
                        <Card.Header
                          className="text-uppercase mc-rfs-4 fw-bold text-light rounded-0 px-2 py-0 border-white"
                          style={{ background: famille.bg }}
                        >
                          Tarifs {famille.famille}
                        </Card.Header>
                        <Table
                          responsive
                          bordered
                          className="m-0"
                          style={{ border: famille.bg }}
                        >
                          <thead>
                            <tr
                              className="text-light border-1 mc-rfs-5"
                              style={{
                                background: famille.bg,
                                padding: "0",
                                margin: "auto",
                              }}
                            >
                              <th className="border-1" style={{ width: "50%" }}>
                                Description
                              </th>
                              <th style={{ width: "9%" }}>Unité</th>
                              <th style={{ width: "15%", textAlign: "end" }}>
                                Prix
                              </th>
                              <th style={{ width: "11%", textAlign: "end" }}>
                                Qté
                              </th>
                              <th
                                className="border-0"
                                style={{ width: "15%", textAlign: "end" }}
                              >
                                Total
                              </th>
                            </tr>
                          </thead>
                          {famille.produits.map((tarifs) => {
                            return (
                              <tbody key={tarifs.produitId}>
                                <tr className="mc-rfs-6">
                                  <td className="ps-2 py-1 align-middle">
                                    {tarifs.libelle}
                                  </td>
                                  <td className="py-1 text-center align-middle">
                                    {tarifs.unite}
                                  </td>
                                  <td className="py-1 text-end align-middle">
                                    {`${tarifs.prixUnitaire.toFixed(2)} €`}
                                  </td>
                                  <td className="py-1 px-1 align-middle">
                                    <InputGroup required>
                                      <Form.Control
                                        className="text-light text-end ps-0 pe-1 mc-rfs-6"
                                        style={{
                                          background: famille.bg,
                                        }}
                                        type="number"
                                        min={0}
                                        placeholder=""
                                        name="userQty"
                                        value={tarifs.qty ?? 0}
                                        onChange={handleChangeQty(
                                          famille.id,
                                          tarifs.produitId,
                                          tarifs.prixUnitaire,
                                          values
                                        )}
                                      />
                                    </InputGroup>
                                  </td>
                                  <td className="text-end align-middle">
                                    {`${tarifs.totalLine.toFixed(2)} €`}
                                  </td>
                                </tr>
                              </tbody>
                            );
                          })}
                          <tfoot
                            className="fw-bold text-light rounded"
                            style={{
                              background: famille.bg,
                            }}
                          >
                            <tr style={{ border: famille.bg }}>
                              <td
                                colSpan={3}
                                className="text-uppercase align-middle"
                              >
                                TOTAL {famille.famille}
                              </td>
                              <td
                                colSpan={2}
                                className="text-end fw-bold align-middle"
                                title="userTotalFamille"
                              >
                                {famille.totalFamille.toFixed(2)} €
                              </td>
                            </tr>
                          </tfoot>
                        </Table>
                      </Card>
                    );
                  })}
                  <Card.Text className="bg-transparent fst-italic fw-bold mc-rfs-5 lh-1 text-danger text-center">
                    * Le poids de la portion de fromage livrée pouvant varier
                    (découpe à la main). La facturation se fera au poids réel
                    lors de la livraison.
                  </Card.Text>
                  <Card
                    className="bg-success d-flex flex-row justify-content-between my-3 p-2 fs-3 fw-bold text-light"
                    // style={{ background: "rgba(203, 54, 20, 1)" }}
                  >
                    <Card.Text className="m-0 mc-rfs-3">
                      ACOMPTE À VERSER
                    </Card.Text>
                    <Card.Text className="mc-rfs-3">
                      {(totalCde / 2).toFixed(2)} €
                    </Card.Text>
                  </Card>
                  <Card
                    className="d-flex flex-row justify-content-between my-3 p-2 fs-3 fw-bold text-light"
                    style={{ background: "rgba(203, 54, 20, 1)" }}
                  >
                    <Card.Text className="m-0 mc-rfs-3">
                      TOTAL COMMANDE
                    </Card.Text>
                    <Card.Text className="mc-rfs-3">
                      {totalCde.toFixed(2)} €
                    </Card.Text>
                  </Card>
                </div>
                {
                  !expiredDateStatus && ""
                  // (<Card className="bg-transparent border-0 text-light">
                  //   {(!values.userName ||
                  //     !values.userTel ||
                  //     !values.userMail) && (
                  //     <Card.Text
                  //       className="m-0 py-1 text-center fst-italic fw-bold  bg-dark"
                  //       style={{ fontSize: "0.8rem" }}
                  //     >
                  //       <span className="mc-stripesup">
                  //         Saisir les coordonnées ci-dessus.
                  //       </span>
                  //     </Card.Text>
                  //   )}
                  //   {!btnResetStatus && (
                  //     <Card.Text
                  //       className="m-0 py-1 text-center fst-italic fw-bold bg-dark"
                  //       style={{ fontSize: "0.8rem" }}
                  //     >
                  //       {/* <span className="mc-stripesup">
                  //         Saisir au moins une ligne de commande pour passer une
                  //         commande.
                  //       </span> */}
                  //       <span className="mc-stripesup">
                  //         Saisir au moins une ligne de commande.
                  //       </span>
                  //     </Card.Text>
                  //   )}
                  // </Card>)
                }
                {/* {values.userName &&
                  values.userTel &&
                  values.userMail &&
                  btnResetStatus &&
                  !expiredDateStatus && ( */}
                {btnResetStatus && !expiredDateStatus && (
                  <Card className="d-flex flex-row justify-content-between bg-transparent border-0">
                    {/* Bouton de passation de commande =========================== */}
                    {/* <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="tooltip-top">Commander</Tooltip>}
                    >
                      <Button
                        variant="primary"
                        disabled={isSubmitting}
                        type="submit"
                        className="mc-btn-neon"
                      >
                        <span className="fw-bold">Commander</span>
                      </Button>
                    </OverlayTrigger> */}
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="tooltip-top">Commander</Tooltip>}
                    >
                      <Button
                        variant="white"
                        role="button"
                        onClick={handleOrder}
                        className="border border-1 border-secondary bg-secondary bg-gradient text-light border-light"
                      >
                        Commander
                      </Button>
                    </OverlayTrigger>

                    <CommandePayment
                      showOrder={showOrder}
                      setShowOrder={setShowOrder}
                      amount={(totalCde / 2).toFixed(2)}
                    />

                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="tooltip-top">Imprimer</Tooltip>}
                    >
                      <Button
                        variant="white"
                        role="button"
                        onClick={handlePrint}
                        className="border border-1 border-secondary bg-secondary bg-gradient text-light border-light"
                      >
                        <IconContext.Provider value={{ size: "1.5rem" }}>
                          <FaIcons.FaPrint />
                        </IconContext.Provider>
                      </Button>
                    </OverlayTrigger>
                    {/* Bouton de reset *****************************************/}
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="tooltip-top">Reset</Tooltip>}
                    >
                      {BtnReset(resetForm)}
                    </OverlayTrigger>
                  </Card>
                )}
                {/* <Card>
                  <Card.Body>
                    <pre>{JSON.stringify(values, null, 2)}</pre>
                  </Card.Body>
                </Card> */}
              </Form>
            )}
          </Formik>
        </Row>
      </Container>
    </div>
  );
};

export default CommandeProduits;
