import React, { useState } from "react";
import "../../../assets/css/jeunes.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import SideMenu from "../../../components/menus/m-side";
import tableCompetitions from "../../../__params/tab-pages/competitions/tab-competitions";
import tableSaisons from "../../../__params/tab-config/tab-saisons";
import TeamsList from "../../../components/pages/competitions/c-teams-list";
import { useNavHeight } from "../../../__utils/context/navheightprovider";

const Schampt = () => {
  const { navbarHeight } = useNavHeight();

  /*************************** Selection of today's date to select current season year */
  let year = 0;

  const todaysDate = new Date();
  for (var i = 0; i < tableSaisons.length; i++) {
    const startDate = Date.parse(tableSaisons[i].startDate);
    const endDate = Date.parse(tableSaisons[i].endDate);

    if (todaysDate > startDate && todaysDate < endDate) {
      year = tableSaisons[i].season;
      break;
    }
  }
  const [currentSeason, setCurrentSeason] = useState(year);

  return (
    <main role="main" className="mc-fond-courts-couverts">
      <Container fluid className="gx-0">
        <Row className="gx-0">
          <Col
            md={3}
            className="gx-0"
            style={{
              position: "sticky",
              top: navbarHeight,
              zIndex: "1000",
            }}
          >
            <SideMenu />
          </Col>
          <Col md={9} className="m-auto">
            <h1 className="mx-2 my-3 mc-rampart mc-rfs-2 mc-title">
              Championnats Seniors
            </h1>
            <div className="mc-container mx-2 my-3">
              <div className="d-flex flex-wrap justify-content-center align-items-center mb-3">
                <h1 className="title text-center mc-rfs-3">
                  Compétitions par équipes
                </h1>
                <h1 className="title mx-2 mc-rfs-3">Saison</h1>
                <div>
                  <Form.Group>
                    <Form.Label className="d-flex">
                      <Form.Select
                        className="mc-rfs-3 text-light fw-bold mc-select"
                        value={currentSeason}
                        onChange={(e) => setCurrentSeason(e.target.value)}
                      >
                        {tableSaisons.map((saison) => {
                          return (
                            <option key={saison.id}>{saison.season}</option>
                          );
                        })}
                      </Form.Select>
                    </Form.Label>
                  </Form.Group>
                </div>
              </div>
              {tableCompetitions
                .filter(
                  (comp) =>
                    comp.season === currentSeason &&
                    comp.category.includes("Seniors")
                )
                .map((compFiltered) => {
                  return (
                    <div
                      className="mc-contest my-2 mc-rfs-4"
                      key={compFiltered.competitionId}
                    >
                      {`${compFiltered.championship} - ${compFiltered.category} - ${compFiltered.gender}`}
                      <div>
                        <TeamsList
                          teamsList={[compFiltered.gender, compFiltered.teams]}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default Schampt;
