import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as formik from "formik";
import Form from "react-bootstrap/Form";
import loginSchema from "../../__params/validationschema/login/loginSchema";
import loginInitialesValues from "../../__params/validationschema/login/loginInitValues";
import { Button, Image, InputGroup } from "react-bootstrap";
import { IconContext } from "react-icons";
import FaIcons from "../../assets/icons/faicons";
import adminImg from "../../assets/img/img-bureau/admin-640x427.jpg";
import { useAuth } from "../../__utils/context/authprovider";
import tabAdmin from "../../__params/authusers/tab-admin";
import tabPwd from "../../__params/authusers/auth-pwd";

const Login = () => {
  const { Formik } = formik;

  const { login } = useAuth();

  const [errorIdMsg, setErrorIdMsg] = useState(null);
  const [errorPwdMsg, setErrorPwdMsg] = useState(null);

  /*************************************************** handle Submit *************/
  const handleLogin = async (values, actions, errors) => {
    const userId = values.userIdAdmin;
    const pwd = values.pwdAdmin;

    const userTab = tabAdmin.find((userTab) => userTab.userId === userId);
    const pwdTab = tabPwd.find((pwdTab) => pwdTab === pwd);

    if (userTab) {
      setErrorIdMsg(null);
      if (pwdTab) {
        setErrorPwdMsg(null);
        await login(true);
      } else {
        setErrorPwdMsg("Mot de passe non valide");
      }
    } else {
      setErrorIdMsg("Identifiant non valide");
    }

    actions.setSubmitting(false);
  };
  /*************************************************** end handle Submit *************/

  return (
    <section>
      <Container
        fluid
        style={{
          backgroundColor: "rgba(135, 209, 249, 0.3)",
        }}
        className="gx-0"
      >
        <Row className="gx-0 align-items-center">
          <Col md={7} className="g-0 d-none d-md-block">
            <Image src={adminImg} alt="type credentials" fluid />
          </Col>
          <Col md={5} className="px-3">
            <Formik
              validationSchema={loginSchema}
              onSubmit={handleLogin}
              initialValues={loginInitialesValues}
            >
              {({
                handleSubmit,
                handleBlur,
                handleChange,
                isSubmitting,
                values,
                touched,
                errors,
              }) => (
                <Form
                  noValidate
                  onSubmit={handleSubmit}
                  className="d-flex flex-column justify-content-around px-2"
                  style={{ height: "32vh" }}
                >
                  <h1 className="mc-rampart mc-rfs-1 text-primary text-center">
                    Espace Admin
                  </h1>
                  <Form.Group controlId="validationFormikUserIdAdmin">
                    <InputGroup hasValidation>
                      <InputGroup.Text
                        className="mc-rfs-6 text-primary border border-1 border-secondary rounded-start justify-content-center fw-bold px-1"
                        id="inputGroupPrepend"
                      >
                        Identifiant :
                      </InputGroup.Text>
                      <Form.Control
                        className="mc-rfs-6 border border-1 border-secondary"
                        type="text"
                        placeholder="email@xxx.com"
                        name="userIdAdmin"
                        value={values.userIdAdmin}
                        aria-describedby="inputGroupPrepend"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isValid={touched.userIdAdmin && !errors.userIdAdmin}
                        isInvalid={touched.userIdAdmin && !!errors.userIdAdmin}
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.userIdAdmin}
                      </Form.Control.Feedback>
                    </InputGroup>
                    {errorIdMsg && !errors.userIdAdmin ? (
                      <small className="text-danger">{errorIdMsg}</small>
                    ) : null}
                  </Form.Group>

                  <Form.Group
                    className="my-2"
                    controlId="validationFormikPwdAdmin"
                  >
                    <InputGroup hasValidation>
                      <InputGroup.Text
                        className="mc-rfs-6 text-primary border border-1 border-secondary rounded-start justify-content-center fw-bold p-1"
                        id="inputGroupPrepend"
                      >
                        Mot de passe :
                      </InputGroup.Text>
                      <Form.Control
                        autoComplete="on"
                        className="mc-rfs-6 border border-1 border-secondary"
                        type="password"
                        placeholder="entrez le mot passe"
                        name="pwdAdmin"
                        value={values.pwdAdmin}
                        aria-describedby="inputGroupPrepend"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isValid={touched.pwdAdmin ?? !errors.pwdAdmin}
                        isInvalid={touched.pwdAdmin && !!errors.pwdAdmin}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.pwdAdmin}
                      </Form.Control.Feedback>
                    </InputGroup>
                    {errorPwdMsg && !errors.pwdAdmin ? (
                      <small className="text-danger">{errorPwdMsg}</small>
                    ) : null}
                  </Form.Group>
                  <Button
                    variant="primary"
                    disabled={isSubmitting || errors.userId || errors.userPwd}
                    type="submit"
                    className="mc-btn-neon w-50 mx-auto"
                  >
                    <span className="fw-bold mc-rfs-5">Se connecter</span>
                    <IconContext.Provider value={{ size: "1rem" }}>
                      <FaIcons.FaChevronRight />
                    </IconContext.Provider>
                  </Button>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Login;
