import badminton from "../../../assets/img/img-animations/01_badminton.png";
import venteFromageVin from "../../../assets/img/img-animations/02_venteFromageVin.png";
import tournoiInterne from "../../../assets/img/img-animations/04_tennis-raquettes.png";
import assembleeGenerale from "../../../assets/img/img-news/imgAG-1079x1079.jpg";
import pickleball from "../../../assets/img/img-animations/06_pickleball.png";
import soireeClub from "../../../assets/img/img-animations/08_soireeClub.png";
import bannerTmc from "../../../assets/img/img-news/Banner-TMC-TCF-450x300.png";
import bonneAnnee from "../../../assets/img/img-news/bonneAnnee2025-450x300.png";
// import rolandGarros from "../../../assets/img/img-animations/09_rolandGarros.png";
// import inscriptions2024 from "../../../assets/img/img-news/adhesion-450x300.png";
// import inscriptions20251 from "../../../assets/img/img-news/adhesionJeunes1.jpg";
// import inscriptions20252 from "../../../assets/img/img-news/adhesionJeunes2.jpg";
import retardataires from "../../../assets/img/img-news/retardataire-450x300.png";
// import repriseCours from "../../../assets/img/img-news/repriseCours-450x300.png";
import jumelage from "../../../assets/img/img-animations/13-jumelage-1000x563.jpg";
import octobreRose from "../../../assets/img/img-octobrerose/02_imgOctobreRose-2024-1068x632.jpeg";

const tabActivitesClub = [
  {
    id: 0,
    image: retardataires,
    animation: "Adhésions",
    desc: "Contacter le moniteur ou un membre du bureau",
    dateDebut: "2024-10-31",
    dateFin: "2024-10-31",
    lieu: "Moniteur ou membre du bureau",
    link: "/contact",
  },
  {
    id: 1,
    image: assembleeGenerale,
    animation: "Assemblée Générale",
    desc: "En tant que membres du club vous êtes conviés. Venez nombreux ...",
    dateDebut: "2024-10-11",
    dateFin: "2024-10-11",
    lieu: "Clubhouse de 19h à 20h",
    link: "#",
  },
  {
    id: 2,
    image: octobreRose,
    animation: "Octobre Rose",
    desc: "Après-midi détente réservée aux Dames au profit de - Les Fées Papillons - association de lutte contre le cancer ...",
    dateDebut: "2024-10-19",
    dateFin: "2024-10-19",
    lieu: "Courts couverts",
    link: "/animations/octobreRose",
  },
  {
    id: 3,
    image: pickleball,
    animation: "Pickleball",
    desc: "Journée détente en pratiquant une activité accessible à tous ...",
    dateDebut: "2024-11-23",
    dateFin: "2024-11-23",
    lieu: "Courts couverts",
    link: "/animations/pickleball",
  },
  {
    id: 4,
    image: soireeClub,
    animation: "Repas de fin d'année",
    desc: "Tous les membres intéressés sont invités. Faites-vous connaître ...",
    dateDebut: "2024-12-13",
    dateFin: "2024-12-13",
    lieu: "Clubhouse",
    link: "/animations/soireeclub",
  },
  {
    id: 5,
    image: venteFromageVin,
    animation: "Vente de Fromages du Jura - Vins du Beaujolais",
    desc: "Commandes de Noël à passer avant le 9 décembre ...",
    dateDebut: "2024-12-21",
    dateFin: "2024-12-21",
    lieu: "Maison des associations",
    link: "/animations/ventes",
  },
  {
    id: 6,
    image: tournoiInterne,
    animation: "Doubles de Noël",
    desc: "Compétition amicale avec changement de partenaire à chaque double ...",
    dateDebut: "2024-12-23",
    dateFin: "2024-12-28",
    lieu: "Courts couverts",
    link: "/competions",
  },
  {
    id: 7,
    image: badminton,
    animation: "Tournoi de Badminton",
    desc: "Tournoi de la St Patrick - Ouvert à tous. Venez nombreux avec vos amis ...",
    dateDebut: "2025-03-15",
    dateFin: "2025-03-15",
    lieu: "Salle polyvalente",
    link: "/animations/badminton",
  },
  {
    id: 8,
    image: venteFromageVin,
    animation: "Vente de Fromages du Jura - Vins du Beaujolais",
    desc: "Commandes à passer avant fin mars ...",
    dateDebut: "2025-04-12",
    dateFin: "2025-04-12",
    lieu: "Maison des associations",
    link: "/animations/ventes",
  },
  {
    id: 9,
    image: soireeClub,
    animation: "Fête du Tennis Club",
    desc: "Repas de fin d'année entre membres et amis du club ...",
    dateDebut: "2025-06-28",
    dateFin: "2025-06-28",
    lieu: "Clubhouse",
    link: "/animations/soireeclub",
  },
  {
    id: 10,
    image: jumelage,
    animation: "Jumelage TC Bad Waldsee",
    desc: "WE convivial avec nos amis allemands du TC de Bad Waldsee ...",
    dateDebut: "2025-07-27",
    dateFin: "2025-07-29",
    lieu: "Courts extérieurs et couverts",
    link: "/events/jumelage",
  },
  {
    id: 11,
    image: pickleball,
    animation: "Pickleball",
    desc: "Journée détente en pratiquant une activité accessible à tous ...",
    dateDebut: "2025-02-08",
    dateFin: "2024-02-08",
    lieu: "Courts couverts",
    link: "/animations/pickleball",
  },
  {
    id: 12,
    image: bannerTmc,
    animation: "TMC - Tournoi Multi-Chances",
    desc: "Tournoi interne sur une journée le dimanche 19 ou 26/1 ou 2 ou 23/2. Réservé aux 30/1 et moins.",
    dateDebut: "2025-01-19",
    dateFin: "2025-02-23",
    lieu: "Courts couverts",
    link: "#",
  },
  {
    id: 13,
    image: bonneAnnee,
    animation: "BONNE ANNÉE 2025",
    desc: "Le TCF vous souhaite une très BONNE ANNÉE 2025.",
    dateDebut: "2025-01-01",
    dateFin: "2025-01-31",
    lieu: "",
    link: "#",
  },
  // {
  //   id: 15,
  //   image: soireeClub,
  //   animation: "Jumelage TC Bad Waldsee",
  //   desc: "Réception du tennis club de Bad Waldsee",
  //   dateDebut: "2024-09-20",
  //   dateFin: "2024-09-22",
  //   lieu: "Clubhouse",
  //   link: "/animations/soireeclub",
  // },
  // {
  //   id: 16,
  //   image: inscriptions20251,
  //   animation: "Adhésions 2025",
  //   desc: "Les insciptions auront lieu de 10h à 12h et 14h à 18h",
  //   dateDebut: "2024-08-31",
  //   dateFin: "2024-09-07",
  //   lieu: "Clubhouse",
  //   link: "/contact",
  // },
  // {
  //   id: 17,
  //   image: repriseCours,
  //   animation: "Reprise des cours",
  //   desc: "Les cours reprendront à partir du lundi 16/9",
  //   dateDebut: "2024-09-16",
  //   dateFin: "2025-06-27",
  //   lieu: "Courts extérieurs ou couverts",
  //   link: "/contact",
  // },
  // {
  //   id: 18,
  //   image: retardataires,
  //   animation: "Adhésions",
  //   desc: "Les inscriptions aux cours continuent",
  //   dateDebut: "2024-09-16",
  //   dateFin: "2025-06-27",
  //   lieu: "Courts extérieurs ou couverts",
  //   link: "/contact",
  // },
  // {
  //   id: 19,
  //   image: pickleball,
  //   animation: "Pickleball",
  //   desc: "Journée détente en pratiquant une activité accessible à tous ...",
  //   dateDebut: "2024-02-10",
  //   dateFin: "2024-02-10",
  //   lieu: "Courts couverts",
  //   link: "/animations/pickleball",
  // },
  // {
  //   id: 20,
  //   image: inscriptions20252,
  //   animation: "Adhésions 2025",
  //   desc: "Les insciptions auront lieu de 10h à 12h durant le forum des assos.",
  //   dateDebut: "2024-09-07",
  //   dateFin: "2024-09-07",
  //   lieu: "Salle polyvalente",
  //   link: "/contact",
  // },
];

export default tabActivitesClub;
