import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import ImageZoom from "react-image-zooom";
import { motion } from "framer-motion";
import SideMenu from "../../../components/menus/m-side";
import TournamentBracket from "../../../components/pages/competitions/c-tournament-bracket-blue";
import tablePlayersTournois from "../../../__params/tab-pages/competitions/tab-tournois";
import tableSaisons from "../../../__params/tab-config/tab-saisons";
import { useNavHeight } from "../../../__utils/context/navheightprovider";

const Stournoi = () => {
  const { navbarHeight } = useNavHeight();
  /*************************** Selection of today's date to select current season year */
  let year = 0;

  const todaysDate = new Date();
  for (var i = 0; i < tableSaisons.length; i++) {
    const startDate = Date.parse(tableSaisons[i].startDate);
    const endDate = Date.parse(tableSaisons[i].endDate);

    if (todaysDate > startDate && todaysDate < endDate) {
      year = tableSaisons[i].season;
      break;
    }
  }

  /* Si déplacement de ce useState au-dessus et mise à jour de setCurrentSeason(year) plantage */
  const [currentSeason, setCurrentSeason] = useState(year);

  const imageVariants = {
    hover: {
      scale: 0.95,
      transition: { duration: 0.5 },
    },
  };

  return (
    <main role="main" className="mc-fond-courts-couverts">
      <Container fluid className="gx-0">
        <Row className="gx-0">
          <Col
            xs={12}
            md={3}
            className="gx-0"
            style={{
              position: "sticky",
              top: navbarHeight,
              zIndex: "1000",
            }}
          >
            <SideMenu />
          </Col>
          <Col xs={12} md={9} className="m-auto">
            <h1 className="mx-2 my-3 mc-rampart mc-rfs-2 mc-title text-center">
              Tournois JEUNES
            </h1>
            <div className="mc-container mx-2 my-3">
              <div
                className="d-flex flex-wrap justify-content-center align-items-center mb-3 rounded-4"
                style={{
                  backgroundColor: "rgba(21, 18, 38, 0.8)",
                }}
              >
                <h1 className="title me-4 mc-rfs-1">Saison</h1>
                <Form.Group>
                  <Form.Label className="d-flex my-2">
                    <Form.Select
                      className="mc-rfs-3 text-light fw-bold mc-select"
                      value={currentSeason}
                      onChange={(e) => setCurrentSeason(e.target.value)}
                    >
                      {tableSaisons.map((saison) => {
                        return <option key={saison.id}>{saison.season}</option>;
                      })}
                    </Form.Select>
                  </Form.Label>
                </Form.Group>
              </div>
              {tablePlayersTournois
                .filter((cat) => cat.category === "Jeunes")
                .map((cat) => {
                  return (
                    <div key={cat.id}>
                      {cat.seasons
                        .filter((season) => season.season === currentSeason)
                        .map((season) => {
                          return (
                            <div key={season.seasonId}>
                              <div className="mb-5">
                                <div>
                                  <p className="my-2 mc-rfs-3">
                                    Édition du{" "}
                                    <span className="mc-contest">
                                      {season.date}
                                    </span>
                                  </p>
                                  <p className="my-2 mc-rfs-4">
                                    Nombre d'inscrits :{" "}
                                    <span className="mc-contest">
                                      {season.registered}
                                    </span>
                                  </p>
                                </div>
                                <div className="d-flex justify-content-center align-items-center text-center m-2">
                                  <motion.div
                                    variants={imageVariants}
                                    whileHover="hover"
                                  >
                                    <ImageZoom
                                      className="rounded-2 border border-2"
                                      src={season.photoFinale}
                                      alt="Tournament poster?"
                                      zoom="200"
                                    />
                                  </motion.div>
                                </div>
                              </div>
                              {season.competitions.map((comp) => {
                                return (
                                  <div key={comp.competitionId}>
                                    <div>
                                      <div className="mc-contest mc-rfs-3 mb-2">
                                        {comp.competitionLabel} - Nombre
                                        d'inscrits : {comp.registered}
                                      </div>
                                      <div>
                                        {comp.events.map((event) => {
                                          return (
                                            <div
                                              className="mb-3 mc-rfs-4 fw-bold"
                                              key={event.eventId}
                                            >
                                              <Row className="mx-0 rounded-3 border">
                                                <div className="mt-2">
                                                  {`${event.eventLabel} - 
                                                  ${event.registered} 
                                                  ${
                                                    event.eventLabel ===
                                                    "Simple Filles"
                                                      ? event.registered > 1
                                                        ? "joueuses"
                                                        : "joueuse"
                                                      : event.registered > 1
                                                      ? "joueurs"
                                                      : "joueur"
                                                  }`}
                                                </div>
                                                <Col xs={12} md={9}>
                                                  <div>
                                                    <TournamentBracket
                                                      event={event}
                                                    />
                                                  </div>
                                                </Col>
                                                <Col
                                                  xs={12}
                                                  md={3}
                                                  className="d-flex align-items-center"
                                                >
                                                  <Image
                                                    src={event.photoFinalEvent}
                                                    thumbnail
                                                  />
                                                </Col>
                                              </Row>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                    </div>
                  );
                })}
            </div>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default Stournoi;
