import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import tabMenus from "../../__params/tab-config/tab-menus";
import { useNavHeight } from "../../__utils/context/navheightprovider";
import "../../assets/css/breadcrumb.css";
import { NavLink } from "react-router-dom";

const MenuBreadcrumb = () => {
  const { pathname } = useLocation();
  const breadcrumbRef = useRef(null);
  const [mcLocation, setMcLocation] = useState(pathname);

  // récupération de la variable globale contexte **********************/
  const { navbarHeight, sideNavHeight, updateBreadNavHeight } = useNavHeight();

  // const filteredBreadMenu = useRef([{ path: "", menus: [{}] }]);

  useEffect(() => {
    const menuHeight = breadcrumbRef?.current.offsetHeight;
    updateBreadNavHeight(menuHeight ?? 0);
  }, [updateBreadNavHeight]);

  useEffect(() => {
    //
    // Test pour extraire le chemin d'origine lorsqu'on a des sous-menus
    //
    const slashPosition = pathname.slice(1).search("/") + 1;

    if (slashPosition > 0) {
      setMcLocation(pathname.slice(0, slashPosition));
    } else {
      setMcLocation(pathname);
    }
  }, [mcLocation, pathname]);

  /********************************************************************/
  /************ Filtre de la table menu pour le breadcrumb ************/
  /********************************************************************/
  let filteredBreadMenu = [{ path: "", menus: [{}] }];

  tabMenus.forEach((menu) => {
    if (menu.name === "breadcrumb") {
      if (pathname.startsWith(menu.path)) {
        filteredBreadMenu = [{ path: menu.path, menus: [...menu.menus] }];
      }
    }
  });

  /********************************************************************/

  return (
    <>
      {filteredBreadMenu.map((item) => {
        return item.menus?.map((menu, index) => (
          <Breadcrumb
            key={index}
            className="mc-breadcrumb"
            style={{
              position: "sticky",
              top: navbarHeight + sideNavHeight,
              zIndex: "1000",
            }}
            ref={breadcrumbRef}
          >
            {menu.showTitle ? menu.title : ""}
            {menu.links.map((link, index) => (
              <div key={index}>
                <NavLink
                  className={({ isActive }) => [
                    isActive
                      ? "mc-breadcrumb-item-active"
                      : "mc-breadcrumb-item",
                  ]}
                  to={`${item.path}${link.subPath}`}
                >
                  {link.linkName}
                </NavLink>
              </div>
            ))}
          </Breadcrumb>
        ));
      })}
    </>
  );
};

export default MenuBreadcrumb;
