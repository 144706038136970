import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import SideMenu from "../../../components/menus/m-side";
import { useNavHeight } from "../../../__utils/context/navheightprovider";

const MentionsLegales = () => {
  const { navbarHeight } = useNavHeight();

  return (
    <main role="main" className="mc-fond-clubhouse">
      <Container fluid className="gx-0">
        <Row className="gx-0">
          <Col
            md={3}
            className="gx-0"
            style={{
              position: "sticky",
              top: navbarHeight,
              zIndex: "1000",
            }}
          >
            <SideMenu />
          </Col>
          <Col md={9} className="m-auto">
            <h1 className="my-3 mc-rampart mc-rfs-2 text-center mc-title">
              Mentions Légales
            </h1>
            <div className="mc-container-club">
              <Row className="mc-container-histoire1">
                <Card className="flex-lg-row align-items-center border border-5 rounded-5 p-2 bg-light">
                  <Col>
                    <Card.Body className="m-3 p-0 mc-rfs-5">
                      <Card.Text>
                        Conformément aux dispositions des Articles 6-III et 19
                        de la Loi n°2004-575 du 21 juin 2004 pour la Confiance
                        dans l’économie numérique, dite L.C.E.N., il est porté à
                        la connaissance des utilisateurs et visiteurs, ci-après
                        l’"<b>Utilisateur</b>", du site
                        https://www.tennis-club-feillens.com, ci-après le
                        <b>"Site"</b>, les présentes mentions légales.
                        <br />
                        <br />
                        La connexion et la navigation sur le Site par
                        l’Utilisateur implique acceptation intégrale et sans
                        réserve des présentes mentions légales.
                        <br />
                        <br />
                        Ces dernières sont accessibles sur le Site à la rubrique
                        « <b>Mentions légales</b> ».
                      </Card.Text>
                      <Card.Title className="fw-bold mc-rfs-4">
                        ARTICLE 1 - L'EDITEUR
                      </Card.Title>
                      <Card.Text>
                        L'édition du Site est assurée par TENNIS CLUB DE
                        FEILLENS Association sportive, immatriculée au Registre
                        du Commerce et des Sociétés de FEILLENS sous le numéro
                        44218526000027 dont le siège social est situé au :
                        <br />
                        <span className="ms-2">
                          - 575 route des Dîmes à Feillens (01570),
                        </span>
                        <br />
                        <span className="ms-2">
                          - Téléphone : +33(0)682948094 - courriel :
                          tcfeillens@gmail.com,
                        </span>
                        <br />
                        <span className="ms-2">
                          - Le Directeur de la publication est Michel CEDDIA
                          ci-après l'"<b>Editeur</b>".
                        </span>
                      </Card.Text>
                    </Card.Body>
                    <Card.Body className="m-3 p-0 mc-rfs-5">
                      <Card.Title className="fw-bold mc-rfs-4">
                        ARTICLE 2 - L'HEBERGEUR
                      </Card.Title>
                      <Card.Text>
                        L'hébergeur du Site est la société <b>IONOS SARL</b>,
                        dont le siège social est situé :
                        <br />
                        <span className="ms-2">
                          - 7 place de la gare, BP 70109 à 57201 Sarreguemines
                          Cedex,
                        </span>
                        <br />
                        <span className="ms-2">
                          - Téléphone : +33(0)970 808 911 - courriel :
                          support@ionos.fr.
                        </span>
                      </Card.Text>
                    </Card.Body>
                    <Card.Body className="m-3 p-0 mc-rfs-5">
                      <Card.Title className="fw-bold mc-rfs-4">
                        ARTICLE 3 - ACCES AU SITE
                      </Card.Title>
                      <Card.Text>
                        Le Site est accessible en tout endroit, 7j/7, 24h/24
                        sauf cas de force majeure, interruption programmée ou
                        non et pouvant découler d’une nécessité de maintenance.
                        <br />
                        En cas de modification, interruption ou suspension du
                        Site, l'Editeur ne saurait être tenu responsable.
                      </Card.Text>
                    </Card.Body>
                    <Card.Body className="m-3 p-0 mc-rfs-5">
                      <Card.Title className="fw-bold mc-rfs-4">
                        ARTICLE 4 - COLLECTE DES DONNEES
                      </Card.Title>
                      <Card.Text>
                        Le site est exempté de déclaration à la Commission
                        Nationale Informatique et Libertés (CNIL) dans la mesure
                        où il ne collecte aucune donnée concernant les
                        utilisateurs.
                        <br />
                        <br />
                        Toute utilisation, reproduction, diffusion,
                        commercialisation, modification de toute ou partie du
                        Site, sans autorisation de l’Editeur est prohibée et
                        pourra entraînée des actions et poursuites judiciaires
                        telles que notamment prévues par le Code de la propriété
                        intellectuelle et le Code civil.
                      </Card.Text>
                    </Card.Body>
                  </Col>
                </Card>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default MentionsLegales;
