import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../../pages/auth/p-login";
import { AdminLayout } from "../routeadmin";
import ErrorPage from "../../components/error/c-errorpage";

const AuthRoute = () => {
  return (
    <Routes>
      <Route element={<AdminLayout />}>
        <Route index element={<Login />} />
        <Route path="/login" element={<Login />} />
        {/******************************* Page Not Found ******************************/}
        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Routes>
  );
};

export default AuthRoute;
