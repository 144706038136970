import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import PhotoAlbum from "react-photo-album";
import photosTennisSante from "../../../__params/tab-pages/enseignement/tab-tennissante";
import MenuSaison from "../../../components/menus/m-saison";
import MenuBreadcrumb from "../../../components/menus/m-breadcrumb";
import { useNavHeight } from "../../../__utils/context/navheightprovider";

const TennisSante = () => {
  const { navbarHeight, sideNavHeight, breadNavHeight } = useNavHeight();
  //
  /* Tri de la table des photos de fin d'année en ordre décroissant */
  //
  const photosSorted = photosTennisSante.sort((a, b) => b.id - a.id);

  //
  /* Récupération des saisons existant dans la table photos de fin d'année */
  //
  const tabSaison = [];

  photosSorted.map((saison) => {
    let season = "";
    if (saison.id !== season) {
      season = saison.id;
      /* La propriété id est nécessaire pour compatibilité avec les autres tables utilisant MenuSaison */
      tabSaison.push({ id: saison.id, season: saison.season });
    }
    return tabSaison;
  });

  return (
    <main role="main" className="mc-fond-clubhouse">
      <Container fluid className="gx-0">
        <Row className="gx-0">
          <Col
            md={3}
            className="gx-0"
            style={{
              position: "sticky",
              top: navbarHeight,
              zIndex: "1000",
            }}
          >
            <MenuSaison saisons={tabSaison} />
          </Col>
          <Col md={9} className="gx-0">
            {/***************************** menu breadcrumb */}
            <MenuBreadcrumb />
            {/************************** Partie Tennis Santé */}
            <Card bg="secondary" className="m-2 border-0">
              <Card.Header className="mc-rampart mc-rfs-2 m-3 text-dark rounded-0 text-center bg-white">
                Tennis Santé
              </Card.Header>
            </Card>
            {/************************** Tennis Santé */}
            {photosSorted.map((saison) => {
              return (
                <div key={saison.id}>
                  <Card bg="transparent" className="my-2 border-0">
                    <span
                      className="mc-marker"
                      style={{
                        top: -(navbarHeight + sideNavHeight + breadNavHeight),
                      }}
                      id={`saison${saison.season}`}
                    ></span>
                    <Card.Body
                      style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
                      className="m-2 p-2 rounded"
                    >
                      <Card.Header
                        className="mc-rampart mc-rfs-3 m-2 text-primary rounded"
                        style={{ backgroundColor: "rgba(255, 255, 255, 1)" }}
                      >
                        {`SAISON ${saison.season}`}
                      </Card.Header>
                    </Card.Body>
                    <Card.Body
                      style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}
                      className="m-2"
                    >
                      <Card.Text className="text-dark mc-rfs-4 mc-rfs-2 fw-bold">
                        {saison.num === 0
                          ? `Édition du ${saison.date} - ${saison.comment}`
                          : `${saison.num} doublettes lors de l'édition du ${saison.date}`}
                      </Card.Text>
                      <PhotoAlbum layout="rows" photos={saison.images} />
                    </Card.Body>
                  </Card>
                </div>
              );
            })}
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default TennisSante;
