import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import SideMenu from "../../components/menus/m-side";
import { useNavHeight } from "../../__utils/context/navheightprovider";
import tabTarifs from "../../__params/tab-pages/tarifs/tab-tarifs";
import DetailTarifs from "../../components/pages/tarifs/c-detail-tarif";
import DetailOffre from "../../components/pages/tarifs/c-detail-offre";
import * as formik from "formik";
import FormObserver from "../../__utils/tools/form-observer";
import tarifInitValues from "../../__params/validationschema/tarifs/tarif-initvalues";
import tarifSchema from "../../__params/validationschema/tarifs/tarif-schema";
import { IconContext } from "react-icons";
import FaIcons from "../../assets/icons/faicons";

const Tarifs = () => {
  const { Formik } = formik;

  const { navbarHeight } = useNavHeight();
  const [totalOffer, setTotalOffer] = useState({
    tariff: 0,
    totBadge: 0,
    totKey: 0,
    totAnimation: 0,
  });
  const [btnToggle, setBtnToggle] = useState(false);

  const handleOffer = (values, actions) => {
    // contruction de la clé d'accès aux tarifs
    let keyTab = "";

    // Type de licence **************************************************
    if (values.offer === "Adulte" || values.offer === "Étudiant") {
      if (values.licence === "Multi-raquettes") {
        keyTab = "1000";
      } else {
        keyTab = "0100";
      }
    } else if (values.offer === "Baby") {
      keyTab = "0010";
    } else {
      keyTab = "0001";
    }

    // Type d'adhésion **************************************************
    if (values.membership === "Adhésion seule") {
      keyTab = keyTab + "10";
    } else {
      keyTab = keyTab + "01";
    }

    // Type de catégorie ************************************************
    if (values.offer === "Baby" || values.offer === "Jeune") {
      keyTab = keyTab + "100";
    } else if (values.offer === "Étudiant") {
      keyTab = keyTab + "010";
    } else if (values.offer === "Adulte") {
      keyTab = keyTab + "001";
    }

    // Période d'adhésion ***********************************************
    if (values.period === "Saison") {
      keyTab = keyTab + "10000000";
    } else {
      if (values.periodMonth === "Décembre") {
        keyTab = keyTab + "01000000";
      } else if (values.periodMonth === "Janvier") {
        keyTab = keyTab + "00100000";
      } else if (values.periodMonth === "Février") {
        keyTab = keyTab + "00010000";
      } else if (values.periodMonth === "Mars") {
        keyTab = keyTab + "00001000";
      } else if (values.periodMonth === "Avril") {
        keyTab = keyTab + "00000100";
      } else if (values.periodMonth === "Mai") {
        keyTab = keyTab + "00000010";
      } else if (values.periodMonth === "Juin") {
        keyTab = keyTab + "00000001";
      }
    }

    // Recherche du tarif constitué *********************************
    const tarifTab = tabTarifs.find((tarif) => tarif.key === keyTab);

    // Calcul de l'adhésion en fonction du nombre de personnes ******
    let totOfferNumPerson = 0;
    if (parseInt(values.OfferNumPerson) > 1) {
      totOfferNumPerson =
        (parseInt(values.OfferNumPerson) - 1) * tarifTab.tariff -
        parseInt(values.OfferNumPerson) * 10;
    }

    // Calcul de l'adhésion en fonction du nombre d'heures **********
    let totMembershipNumHours = 0;
    if (parseInt(values.membershipNumHours) > 1) {
      totMembershipNumHours = (parseInt(values.membershipNumHours) - 1) * 155;
    }

    // Calcul de la caution des badges et clés **********************
    const totBadge = values.badgeNum ? parseInt(values.badgeNum) * 5 : 0;
    const totKey = values.keyNum ? parseInt(values.keyNum) * 5 : 0;

    // Calcul de la participation aux animations **********************
    const totAnimation = values.animation * 10;

    setTotalOffer({
      tariff: tarifTab?.tariff + totOfferNumPerson + totMembershipNumHours,
      totBadge: totBadge,
      totKey: totKey,
      totAnimation: totAnimation,
    });

    actions.setSubmitting(false);

    setBtnToggle(!btnToggle);
    // actions.resetForm();
  };

  const handleRazTotOffer = () => {
    setTotalOffer({
      tariff: 0,
      totBadge: 0,
      totKey: 0,
      totAnimation: 0,
    });
  };

  return (
    <div className="mc-fond-clubhouse">
      <Container fluid className="gx-0">
        <Row className="gx-0">
          <Col
            md={3}
            className="gx-0"
            style={{
              position: "sticky",
              top: navbarHeight,
              zIndex: "1000",
            }}
          >
            <SideMenu />
          </Col>
          <Col md={9} className="">
            <h1 className="mc-rfs-1 text-center text-primary bg-light m-2 py-2 border border-5 rounded mc-rampart">
              TARIFS SAISON 2024
            </h1>
            <div className="m-2">
              <Card
                className="shadow-lg"
                style={{ backgroundColor: "rgba(20, 20, 20, 0.7)" }}
              >
                <Formik
                  initialValues={tarifInitValues}
                  validationSchema={tarifSchema}
                  onSubmit={handleOffer}
                  onReset={handleRazTotOffer}
                >
                  {({
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    handleReset,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <FormObserver setBtnToggle={setBtnToggle} />
                      <Row className="gx-0">
                        <Col md={7}>
                          <DetailTarifs
                            values={values}
                            touched={touched}
                            errors={errors}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            handleReset={handleReset}
                          />
                          <div className="d-flex justify-content-center">
                            <Button
                              variant={btnToggle ? "success" : "danger"}
                              className="mc-btn-neon w-auto my-4 mc-rfs-5"
                              type="submit"
                              // disabled={isSubmitting}
                            >
                              <span className="fw-bold">Calcul de l'offre</span>
                              <IconContext.Provider value={{ size: "1rem" }}>
                                <FaIcons.FaChevronRight />
                              </IconContext.Provider>
                            </Button>
                          </div>
                        </Col>
                        <Col md={5}>
                          <DetailOffre
                            values={values}
                            totalOffer={totalOffer}
                            btnToggle={btnToggle}
                          />
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Tarifs;
