import { NavLink } from "react-router-dom";
import ImageZoom from "react-image-zooom";
import {
  Row,
  Col,
  Card,
  ListGroup,
  ListGroupItem,
  Nav,
  Table,
} from "react-bootstrap";
import { motion } from "framer-motion";
import { useNavHeight } from "../../../__utils/context/navheightprovider";
import findImage from "../../../__utils/tools/find-image";
import tabEnseignement from "../../../__params/tab-pages/enseignement/tab-enseignement";
import { IconContext } from "react-icons";
import { FaChevronRight } from "react-icons/fa";

const imageVariants = {
  hover: {
    scale: 0.95,
    transition: { duration: 0.5 },
  },
};

const buttonVariants = {
  hover: {
    // scale: [1, 1.2, 1, 1.2, 1, 1.2, 1, 1.2, 1, 1.2, 1],
    scale: 1.3,
    textShadow: "0 0 8px rgb(255, 255, 255)",
    transition: { duration: 0.5, repeat: Infinity },
  },
};

const TennisSante = () => {
  const { navbarHeight, sideNavHeight, breadNavHeight } = useNavHeight();
  return (
    /***********************************************************/
    /************************** Partie Tennis Santé / Bien-Être */
    /************************************************************/
    <Card
      className="m-2 border border-4"
      style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
    >
      <span
        className="mc-marker"
        style={{
          top: -(navbarHeight + sideNavHeight + breadNavHeight + 10),
        }}
        id="tennissante"
      ></span>
      <Card bg="transparent" className="border-0">
        {/* <Card.Header className="mc-rampart mc-rfs-2 m-2 py-2 px-1 fw-bold text-primary text-center rounded-2 bg-light">
                    Tennis Santé - Bien-Être
                  </Card.Header> */}
        <Card.Header className="m-2 py-2 px-0 fw-bold text-primary text-center rounded-2 bg-light">
          <Card.Text className="mc-rampart mc-rfs-2 m-0">
            Tennis Santé - Bien-Être
          </Card.Text>
          <Nav className="mc-rfs-4 position-absolute top-0 end-0 mt-3 me-4">
            <NavLink
              className="p-1 px-2 border border-2 border-muted bg-success rounded text-center nav-link"
              to="/enseignement/tennissante"
            >
              <motion.div
                className="text-light fw-semibold"
                variants={buttonVariants}
                whileHover="hover"
              >
                <span className="pe-2 align-middle">Voir</span>
                <IconContext.Provider value={{ size: "1em" }}>
                  <FaChevronRight />
                </IconContext.Provider>
              </motion.div>
            </NavLink>
          </Nav>
        </Card.Header>
        <Card.Body>
          <Card.Title className="fw-bold" style={{ color: "rgb(250, 100, 0)" }}>
            Le tennis au service de la santé, un véritable enjeu de santé
            publique
          </Card.Title>
        </Card.Body>
        <Row className="g-0">
          <Col lg={7}>
            <Card.Body className="py-0">
              <Card.Text>
                Le tennis est un sport qui contribue au bien-être physique,
                mental et social, les trois composantes de la définition de la
                santé. Il se révèle, en effet, être en tête dans les études
                internationales en termes de bénéfices pour la santé.
                <br />
                Facteur de prévention, il est aussi un véritable outil
                thérapeutique dans la lutte contre les maladies chroniques et le
                vieillissement. Il peut se jouer à tous les âges et quel que
                soit son passé sportif.
                <br />
                La FFT a ainsi créé un programme adapté et sécurisé destiné à
                tous les publics, notamment aux personnes atteintes de certaines
                pathologies chroniques et/ou concernées par le vieillissement :{" "}
                <span className="fw-bold" style={{ color: "rgb(250, 100, 0)" }}>
                  le Tennis Santé
                </span>
                .
              </Card.Text>
            </Card.Body>
          </Col>
          <Col lg={5}>
            <Card
              style={{ backgroundColor: "rgb(250, 100, 0)" }}
              className="mx-2 my-4 p-2 rounded-3 text-center border-0"
            >
              <Table
                responsive
                hover
                bordered
                className="m-0"
                style={{
                  border: "rgba(225, 100, 0, 1)",
                  backgroundColor: "rgba(225, 210, 156, 1)",
                }}
              >
                <thead
                  className="text-light"
                  style={{
                    backgroundColor: "rgba(250, 100, 0, 1)",
                  }}
                >
                  <tr>
                    <th colSpan={"2"}>Horaires Tennis Santé - Bien-être</th>
                  </tr>
                </thead>
                <tbody className="fw-bold">
                  <tr>
                    <td>Lundi</td>
                    <td>Jeudi</td>
                  </tr>
                  <tr>
                    <td>-</td>
                    <td>9h30 à 10h45</td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
        <Card.Body className="pb-0">
          <Card.Subtitle className="fw-bold mb-2">
            La FFT, une fédération innovante dans le développement du sport
            santé
          </Card.Subtitle>
        </Card.Body>
        <Row className="g-0">
          <Col lg={7}>
            <Card.Body className="py-0">
              <Card.Text>
                Dès 2014, la Fédération Française de Tennis a réuni une partie
                de ses entités (le pôle fédéral, la direction technique
                nationale, la commission fédérale médicale et celles des ligues)
                pour mettre en place le programme Tennis Santé, en accord avec
                les recommandations du CNOSF. Son objectif est alors de prévenir
                et de réduire les facteurs de risque ainsi que les limitations
                fonctionnelles liées à la maladie. La FFT a ainsi collaboré avec
                des médecins spécialistes – le docteur Caroline Cuvier
                (oncologue), le professeur Étienne Larger (endocrinologue) et le
                professeur Nicolas Mansencal (cardiologue) – afin d’élaborer des
                protocoles adaptés aux différents publics cibles. Depuis 2016,
                l’activité physique et sportive (APS) est reconnue dans le Code
                de santé publique comme une thérapeutique non médicamenteuse
                pouvant être prescrite par les médecins.
              </Card.Text>
            </Card.Body>
          </Col>
          <Col lg={5}>
            <Card.Img
              src={findImage(tabEnseignement, "Label")}
              alt="Photo Label?"
              className="mx-2 my-4 rounded-3"
              style={{ width: "90%" }}
            />
          </Col>
        </Row>
        <Card.Body>
          <Card.Subtitle className="fw-bold mb-2">
            Le Tennis Santé : outil thérapeutique, plaisir et lien social
          </Card.Subtitle>
          <Card.Text>
            Le programme Tennis Santé est adapté aux personnes concernées par le
            vieillissement et/ou atteintes de diverses pathologies chroniques :
          </Card.Text>
          <ListGroup numbered className="ms-2">
            <ListGroupItem className="border-0 bg-transparent py-1">
              artériopathie oblitérante des membres inférieurs (AOMI) ;
            </ListGroupItem>
            <ListGroupItem className="border-0 bg-transparent py-1">
              hypertension artérielle (HTA) ;
            </ListGroupItem>
            <ListGroupItem className="border-0 bg-transparent py-1">
              coronaropathies ;
            </ListGroupItem>
            <ListGroupItem className="border-0 bg-transparent py-1">
              surpoids et obésité ;
            </ListGroupItem>
            <ListGroupItem className="border-0 bg-transparent py-1">
              diabète de type 2 ;
            </ListGroupItem>
            <ListGroupItem className="border-0 bg-transparent py-1">
              stress, anxiété, dépression ;
            </ListGroupItem>
            <ListGroupItem className="border-0 bg-transparent py-1">
              cancer (sein, prostate, colon) ;
            </ListGroupItem>
            <ListGroupItem className="border-0 bg-transparent py-1">
              pathologies articulaires (arthrose, spondylarthrite, polyarthrite)
              ;
            </ListGroupItem>
            <ListGroupItem className="border-0 bg-transparent pt-1 pb-4">
              bronchopneumopathie chronique obstructive (BPCO).
            </ListGroupItem>
          </ListGroup>
          <Card.Text>
            En plus de ses vertus et/ou effets thérapeutiques, le Tennis Santé
            procure beaucoup de plaisir et contribue au maintien et au
            développement du lien social.
          </Card.Text>
        </Card.Body>
        <Card.Body>
          <Card.Title className="fw-bold" style={{ color: "rgb(250, 100, 0)" }}>
            Le Tennis Santé: une pratique adaptée, personnalisée et sécurisée
          </Card.Title>
        </Card.Body>
        <Card.Body className="py-0">
          <Card.Text>
            La FFT contribue à la santé de ses pratiquants grâce au
            développement du Tennis Santé.
            <br />
            Le Tennis Santé doit savoir s’adapter à l’âge et à l’état de santé
            de chacun.
            <br />
            Pour cela et afin de garantir une pratique sécurisée, une formation
            fédérale a été mise en place dans chaque ligue.
          </Card.Text>
        </Card.Body>
        <Card.Body>
          <Card.Subtitle className="fw-bold pb-2">
            Déploiement du programme national
          </Card.Subtitle>
          <Card.Text>
            Le développement du programme Tennis Santé au niveau national repose
            sur un trinôme composé d’un médecin référent, d’un enseignant
            diplômé d’État et d’un conseiller en développement. Ils ont pour
            mission l’accompagnement des clubs volontaires et la formation des
            enseignants basée sur le programme fédéral Tennis Santé donnant
            toutes les clés et informations pour initier la pratique du Tennis
            Santé.
          </Card.Text>
        </Card.Body>
        <Card.Body>
          <Card.Subtitle className="fw-bold pb-2">
            L’enseignant : la clé du succès de la pratique
          </Card.Subtitle>
          <Card.Text>
            Les formations Tennis Santé sont pour l’instant réservées aux
            enseignants professionnels de Tennis (DE ou DES).
            <br />À l’issue de cette formation, l’enseignant connaît les
            caractéristiques très générales des principales pathologies
            chroniques et des effets du vieillissement, est capable d’encourager
            les comportements favorables à la santé, d’évaluer les capacités
            physiques de chacun, de concevoir une séance adaptée la plus ludique
            possible, d’encourager et de motiver.
          </Card.Text>
        </Card.Body>
        <Card.Body>
          <Card.Subtitle className="fw-bold pb-2">
            Les séances de Tennis Santé: des moments de plaisir et de partage
          </Card.Subtitle>
        </Card.Body>
        <Row className="g-0">
          <Col lg={7}>
            <Card.Body className="py-0">
              <Card.Text>
                Sous la forme d’une séance collective hebdomadaire rassemblant
                huit joueurs maximum, les enseignants s’appuient sur un matériel
                évolutif qui permet à chaque pratiquant, novice ou non, de
                réaliser des échanges et de s’amuser dès la première séance. Ce
                matériel s’adapte alors à l’état de santé, à l’âge et aux
                capacités physiques de chacun :
              </Card.Text>
            </Card.Body>
            <ListGroup numbered className="ms-3">
              <ListGroupItem className="border-0 bg-transparent py-1">
                terrains de taille variable ;
              </ListGroupItem>
              <ListGroupItem className="border-0 bg-transparent py-1">
                filets de hauteur modulable ;
              </ListGroupItem>
              <ListGroupItem className="border-0 bg-transparent py-1">
                balles plus lentes ;
              </ListGroupItem>
              <ListGroupItem className="border-0 bg-transparent py-1">
                raquettes de taille adaptée permettant ainsi de limiter les
                déplacements et de faciliter les échanges.
              </ListGroupItem>
              <ListGroupItem className="border-0 bg-transparent pt-1 pb-4">
                bronchopneumopathie chronique obstructive (BPCO).
              </ListGroupItem>
            </ListGroup>{" "}
          </Col>
          <Col lg={5}>
            <Card.Img
              src={findImage(tabEnseignement, "TennisSante")}
              alt="Tennis santé?"
              className="ms-3 rounded-3"
              style={{ width: "95%" }}
            />
          </Col>
        </Row>
        <Card.Body>
          <Card.Text>
            Ces séances débutent toujours avec un temps suffisant réservé à
            l’accueil. L’enseignant doit prendre le temps de s’informer sur la
            forme et les éventuels problèmes de chacun. Après l’énoncé des
            objectifs de la séance, l’échauffement qui est un temps très
            important peut commencer.
            <br />
            L’enseignant doit aussi chercher à développer tous les éléments de
            la condition physique : endurance, force musculaire, équilibre,
            souplesse. Tout au long de la séance, l’enseignant devra respecter
            les trois principes fondamentaux d’une séance sport-santé :
          </Card.Text>
        </Card.Body>
        <ListGroup numbered className="ms-3 border-0">
          <ListGroupItem className="border-0 bg-transparent py-1">
            adaptation à chacun ;
          </ListGroupItem>
          <ListGroupItem className="border-0 bg-transparent py-1">
            progressivité ;
          </ListGroupItem>
          <ListGroupItem className="border-0 bg-transparent pt-1 pb-4">
            et enfin plaisir et réussite.
          </ListGroupItem>
        </ListGroup>{" "}
        <Card.Body className="py-0">
          <Card.Text>
            Retour au calme et debriefing clôturent la séance dans un dernier
            moment de convivialité.
            <br />
            Ecoute, vigilance et bienveillance doivent faire partie de l’ADN de
            l’enseignant.
          </Card.Text>
        </Card.Body>
        <Card.Body>
          <Card.Subtitle className="fw-bold mb-2">
            Vous êtes intéressé ou souhaitez vous inscrire ?{" "}
          </Card.Subtitle>
          <Card.Text>
            N'hésitez pas à nous{" "}
            <a className="text-muted fw-bold" href="/contact">
              <span>contacter</span>
            </a>
            .
          </Card.Text>
        </Card.Body>
      </Card>
      <Card className="flex-lg-row justify-content-between mx-4 mt-3 border-0">
        <Card.Body className="m-2 rounded-3 border border-5 border-warning">
          <motion.div variants={imageVariants} whileHover="hover">
            <ImageZoom
              src={findImage(tabEnseignement, "TennisSanteAffiche")}
              alt="Affiche Tennis Santé?"
              zoom="200"
            />
          </motion.div>
        </Card.Body>
        <Card.Body className="m-2 rounded-3 border border-5 border-warning">
          <motion.div variants={imageVariants} whileHover="hover">
            <ImageZoom
              src={findImage(tabEnseignement, "TennisSanteArticle")}
              alt="Article Tennis Santé?"
              zoom="200"
            />
          </motion.div>
        </Card.Body>
      </Card>
    </Card>
  );
};

export default TennisSante;
