import React from "react";
import { Routes, Route } from "react-router-dom";
import ErrorPage from "../../components/error/c-errorpage";
import {
  AdminLayout,
  GestionClub,
  Tarifs,
  FicheMembre,
  ListeDesMembres,
} from ".";

const AdminRoute = () => {
  return (
    <Routes>
      <Route element={<AdminLayout />}>
        <Route index element={<GestionClub />} />
        <Route path="/admin" element={<GestionClub />} />
        <Route>
          <Route path="/tarifs" element={<Tarifs />} />
          <Route path="/fichemembre" element={<FicheMembre />} />
          <Route path="/listedesmembres" element={<ListeDesMembres />} />
        </Route>
        {/******************************* Page Not Found ******************************/}
        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Routes>
  );
};

export default AdminRoute;
