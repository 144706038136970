import ImageZoom from "react-image-zooom";
import { motion } from "framer-motion";
import {
  Row,
  Col,
  Card,
  ListGroup,
  ListGroupItem,
  Nav,
  NavLink,
  Image,
} from "react-bootstrap";
import { useNavHeight } from "../../../__utils/context/navheightprovider";
import findImage from "../../../__utils/tools/find-image";
import tabEnseignement from "../../../__params/tab-pages/enseignement/tab-enseignement";
import { IconContext } from "react-icons";
import { FaChevronRight } from "react-icons/fa";

const imageVariants = {
  hover: {
    scale: 0.95,
    transition: { duration: 0.5 },
  },
};

const buttonVariants = {
  hover: {
    // scale: [1, 1.2, 1, 1.2, 1, 1.2, 1, 1.2, 1, 1.2, 1],
    scale: 1.3,
    textShadow: "0 0 8px rgb(255, 255, 255)",
    transition: { duration: 0.5, repeat: Infinity },
  },
};

const Pickleball = () => {
  const { navbarHeight, sideNavHeight, breadNavHeight } = useNavHeight();
  return (
    /***********************************************************/
    /************************** Pickleball *********************/
    /***********************************************************/
    <Card
      className="m-2 border border-4"
      style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
    >
      <span
        className="mc-marker"
        style={{
          top: -(navbarHeight + sideNavHeight + breadNavHeight + 10),
        }}
        id="pickleball"
      ></span>
      <Card bg="transparent" className="border-0">
        <Card.Header className="m-2 py-2 px-0 fw-bold text-primary text-center rounded-2 bg-light">
          <Card.Text className="mc-rampart mc-rfs-2 m-0">Pickleball</Card.Text>
          <Nav className="mc-rfs-4 position-absolute top-0 end-0 mt-3 me-4">
            <NavLink
              className="p-1 px-2 border border-2 border-muted bg-danger rounded text-center nav-link"
              to="/animations/pickleball"
            >
              <motion.div
                className="text-light fw-semibold"
                variants={buttonVariants}
                whileHover="hover"
              >
                <span className="pe-2 align-middle">Voir</span>
                <IconContext.Provider value={{ size: "1em" }}>
                  <FaChevronRight />
                </IconContext.Provider>
              </motion.div>
            </NavLink>
          </Nav>
        </Card.Header>
        <Card.Body>
          <Card.Title className="fw-bold" style={{ color: "rgb(250, 100, 0)" }}>
            Le pickleball en France
          </Card.Title>
        </Card.Body>
        <Row className="g-0">
          <Col lg={7}>
            <Card.Body className="py-0">
              <Card.Text>
                Sport de raquette facile d’accès et extrêmement ludique, le
                pickleball met en avant l’esprit d’équipe et se pratique le plus
                souvent en double (même si le simple n’est pas oublié !).
                <br />
                <br />
                Extrêmement populaire outre-Atlantique, le pickleball connaît un
                fort développement en Europe (Italie, Espagne, Royaume-Uni,
                Pays-Bas, etc.). Sport facile et adapté à tous les publics, il
                peut se pratiquer en simple comme en double. Les
                caractéristiques spécifiques de la balle en plastique, creuse et
                perforée, permettent de réaliser des échanges dès les premiers
                instants. Ainsi l’expérience procure un plaisir immédiat et
                laisse place à une progression rapide.
                <br />
                <br />
                Sport intergénérationnel et rassembleur, il permet de jouer en
                famille, entre amis ou encore entre collègues, quel que soit
                l’âge, le niveau et la condition physique des joueurs. Le
                pickleball met en avant des notions d’amusement, de partage et
                de rencontres.
                <br />
                <br />
                Des aspects compétitifs et stimulants, prennent place lorsque le
                niveau de jeu s’élève. En effet, dans ces cas, des notions
                tactiques et stratégiques apparaîtront dans l’esprit des
                joueurs. sportif.
              </Card.Text>
            </Card.Body>
          </Col>
          <Col lg={{ span: 4, offset: 1 }}>
            <Card.Text className="text-center">
              <Image
                rounded
                src={findImage(tabEnseignement, "PickleballJeu")}
                alt="Pickleball?"
                style={{ width: "100%" }}
              />
            </Card.Text>
          </Col>
        </Row>
        <Row className="g-0">
          <Col lg={7}>
            <Card.Body className="pb-0">
              <Card.Title
                className="fw-bold"
                style={{ color: "rgb(250, 100, 0)" }}
              >
                Le matériel
              </Card.Title>
            </Card.Body>
            <Card.Body className="pt-0">
              <ListGroup className="ms-1 border-0">
                <ListGroupItem className="border-0 bg-transparent py-1">
                  <span className="fw-bold">Le terrain</span>
                  <br />
                  Dimensions : 6,10 m x 13,41 m lignes comprises.
                  <br />
                  Dimensions d’un terrain aux normes FFT pour réaliser des
                  tournois : 9 m x 18 m (côté et recul compris).
                </ListGroupItem>
                <ListGroupItem className="border-0 bg-transparent py-1">
                  <span className="fw-bold">La Raquette</span>
                  <br />
                  plus grande qu’une raquette de tennis de table, la raquette de
                  pickleball est pleine et fabriquée de fibre de verre / carbone
                  ou de graphite. Très légère, elle pèse 240 gr en moyenne, et
                  mesure aux alentours des 40 cm de haut par 20 cm de large.
                </ListGroupItem>
                <ListGroupItem className="border-0 bg-transparent py-1">
                  <span className="fw-bold">La balle</span>
                  <br />
                  légèrement plus grande qu’une balle de tennis, elle est creuse
                  et perforée de trous. Il existe différents types de balles,
                  pour le jeu en intérieur et le jeu en extérieur, qui diffèrent
                  par le nombre de trous (26 à 40) et leur taille.
                </ListGroupItem>
                <ListGroupItem className="border-0 bg-transparent pt-1 pb-4">
                  <span className="fw-bold">Le filet</span>
                  <br />
                  large de 6,70 m, sa hauteur doit être de 86 cm au centre et de
                  91 cm au niveau des poteaux.
                </ListGroupItem>
              </ListGroup>{" "}
              <Card.Text></Card.Text>
            </Card.Body>
          </Col>
          <Col lg={5}>
            <motion.div variants={imageVariants} whileHover="hover">
              <ImageZoom
                className="mt-5 rounded-3"
                src={findImage(tabEnseignement, "PickleballTerrain")}
                alt="Pickleball terrain?"
                zoom="200"
              />
            </motion.div>
          </Col>
        </Row>
        <Row className="g-0">
          <Card.Body className="pb-0">
            <Card.Title
              className="fw-bold"
              style={{ color: "rgb(250, 100, 0)" }}
            >
              Les règles du Pickleball
            </Card.Title>
          </Card.Body>
          <Row>
            <Col lg={9}>
              <Card.Body className="m-2 rounded-3">
                <motion.div variants={imageVariants} whileHover="hover">
                  <ImageZoom
                    className="ms-3 rounded-3"
                    src={findImage(tabEnseignement, "PickleballRegles")}
                    alt="Affiche Règles Pickleball?"
                    zoom="200"
                  />
                </motion.div>
              </Card.Body>
            </Col>
            <Col lg={3}>
              <Card.Body className="text-center m-2">
                <Image
                  src={findImage(tabEnseignement, "PickleballRaquette")}
                  alt="Pickleball raquette et balle?"
                  style={{ width: "60%" }}
                />
              </Card.Body>
              <Card.Body className="text-center">
                <Image
                  rounded
                  src={findImage(tabEnseignement, "PickleballBalle")}
                  alt="Pickleball balle ?"
                  className=""
                  style={{ width: "80%" }}
                />
              </Card.Body>
            </Col>
          </Row>
        </Row>
      </Card>
    </Card>
  );
};

export default Pickleball;
