import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import tabBureau from "../../../__params/tab-pages/leclub/tab-bureau";
import SideMenu from "../../../components/menus/m-side";
import { useNavHeight } from "../../../__utils/context/navheightprovider";

const LeBureau = () => {
  const { navbarHeight } = useNavHeight();

  return (
    <main role="main" className="mc-fond-clubhouse">
      <Container fluid className="gx-0">
        <Row className="gx-0">
          <Col
            md={3}
            className="gx-0"
            style={{
              position: "sticky",
              top: navbarHeight,
              zIndex: "1000",
            }}
          >
            <SideMenu />
          </Col>
          <Col md={9} className="m-auto">
            <h1 className="my-3 mc-rampart mc-title text-center mc-rfs-1">
              Le Bureau
            </h1>
            <div className="mc-container-club">
              <Row>
                {tabBureau.map((card) => (
                  <Col xl={4} md={6} key={card.id} className="p-2">
                    <Card className="my-3 bg-light border border-success border-5 rounded-4">
                      <Row>
                        <Col xs={4} md={4}>
                          <Card.Img
                            src={card.photoId}
                            alt="Photo Id?"
                            className="rounded-3"
                          />
                        </Col>
                        <Col xs={8} md={8}>
                          <Card.Body>
                            <Card.Title className="mc-rampart text-primary mc-rfs-4">
                              {card.position}
                            </Card.Title>
                            <Card.Title className="mc-rfs-5 text-primary  fw-bold">
                              {card.name}
                            </Card.Title>
                            <Card.Text className="mc-rfs-6">
                              {card.phone}
                            </Card.Text>
                          </Card.Body>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <Card.Body>
                            <Card.Text className="mc-rfs-5 fst-italic fw-bold">
                              Commission : {card.commission}
                            </Card.Text>
                          </Card.Body>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default LeBureau;
