import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Weather from "../../../components/pages/home/c-weather";
import News from "../../../components/pages/home/c-news";
import Partners from "../../../components/pages/home/c-partners";
import Reserve from "../../../components/pages/home/c-reserve";
import Agenda from "../../../components/pages/home/c-agenda";
import "../../../assets/css/home.css";

const Accueil = () => {
  return (
    <main role="main" className="mc-fond-ecran">
      <Container fluid>
        <Row className="gx-0 pt-3 ps-4">
          <span className="my-4 mc-rampart fs-1 text-primary">
            À ne pas manquer !
          </span>
        </Row>
        <Row>
          <Agenda />
        </Row>
        <Row className="gx-0 pt-3 ps-4">
          <span className="mc-rampart fs-1 text-primary">Actualités</span>
        </Row>
        <Row>
          <News />
          <Weather />
          <Reserve />
        </Row>
        <Row className="gx-0 py-3 px-4">
          <span className="mc-partners mc-rampart fs-1">Nos partenaires</span>
        </Row>
        <Row className="pt-3">
          <Partners />
        </Row>
      </Container>
    </main>
  );
};

export default Accueil;
