import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Nav from "react-bootstrap/Nav";
import logoTCF from "../../assets/img/img-header/logoTCF1.png";
import { NavLink } from "react-router-dom";
import tabMenus from "../../__params/tab-config/tab-menus";

const Footer = () => {
  const navbarLinks = tabMenus
    .filter((menu) => menu.name === "navbar")
    .flatMap((menu) => menu.menus.flatMap((subMenu) => subMenu.links));

  return (
    <footer role="contentinfo" className="footer">
      <Container fluid className="pt-2 border-top">
        <Row className="justify-content-between mb-2">
          <Col xs={12} md={5}>
            <div className="ps-2">
              <OverlayTrigger
                placement={"bottom"}
                delay={{ show: 100, hide: 200 }}
                overlay={
                  <Tooltip id={"tooltip-TCF"}>Tennis Club de Feillens</Tooltip>
                }
              >
                <a
                  className="nav-link"
                  href="https://www.tennis-club-feillens.com"
                  target="_blank"
                  rel="noreferrer noopenner"
                >
                  <img
                    className="img-fluid p-2"
                    src={logoTCF}
                    alt="Logo TCF ?"
                    width={50}
                    height={50}
                  ></img>
                  <span className="fw-bold text-muted">
                    TENNIS CLUB DE FEILLENS
                  </span>
                </a>
              </OverlayTrigger>
              <p className="small my-0 text-muted">
                Siège : 575 route des Dîmes - 01570 FEILLENS
              </p>
              <p className="small text-muted">
                Clubhouse - <strong>2 courts extérieurs</strong>
              </p>
              <hr></hr>
              <p className="small my-0 text-muted">
                Rue de l'école privée - 01570 FEILLENS
              </p>
              <p className="small text-muted">
                Clubhouse - <strong>2 courts couverts</strong>
              </p>
            </div>
          </Col>
          <Col xs={12} md={7} className="ps-2">
            <p className="fw-bold text-muted ps-3 my-3">Liens du site</p>
            <Nav defaultActiveKey="/accueil" className="d-wrap">
              <Col className="d-flex flex-wrap mc-rfs-6">
                {navbarLinks.map((link, index) => (
                  <Nav.Item key={index}>
                    <NavLink
                      to={link.subPath}
                      className="mc-footer-link nav-link text-muted"
                    >
                      {link.linkName}
                    </NavLink>
                  </Nav.Item>
                ))}
              </Col>
            </Nav>
            <p className="fw-bold text-muted ps-3 pt-4">Contactez-nous</p>
            <a className="text-muted text-decoration-none ps-3" href="/contact">
              <span className="mc-tcf-email text-muted">
                tcfeillens@gmail.com
              </span>
            </a>
          </Col>
        </Row>
        <Row>
          <Col
            className="text-center py-2 text-light"
            style={{ backgroundColor: "#595959" }}
          >
            <small>© MC2, 2023. All rights reserved.</small>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
