import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import PhotoAlbum from "react-photo-album";
import photosOctobreRose from "../../../__params/tab-pages/animations/tab-octobreRose";
import MenuSaison from "../../../components/menus/m-saison";
import MenuBreadcrumbEvents from "../../../components/menus/m-breadcrumb";
import { useNavHeight } from "../../../__utils/context/navheightprovider";

const OctobreRose = () => {
  const { navbarHeight, sideNavHeight, breadNavHeight } = useNavHeight();
  //
  /* Tri de la table des photos de fin d'année en ordre décroissant */
  //
  const photosSorted = photosOctobreRose.sort((a, b) => b.season - a.season);
  //
  /* Récupération des saisons existant dans la table photos octobre rose */
  //
  const tabSaison = [];
  let season = "";

  photosSorted.map((saison) => {
    if (saison.season !== season) {
      season = saison.season;
      /* La propriété id est nécessaire pour compatibilité avec les autres tables utilisant MenuSaison */
      tabSaison.push({ id: season, season: saison.season });
    }
    return {
      tabSaison,
    };
  });
  /* FIN - Récupération des saisons existant dans la table photos octobre rose */

  return (
    <main role="main" className="mc-fond-courts-couverts">
      <Container fluid className="gx-0">
        <Row className="gx-0">
          <Col
            md={3}
            className="gx-0"
            style={{
              position: "sticky",
              top: navbarHeight,
              zIndex: "1000",
            }}
          >
            <MenuSaison saisons={tabSaison} />
          </Col>
          <Col md={9} className="gx-0">
            {/***************************** menu breadcrumb */}
            <MenuBreadcrumbEvents />
            {/************************** Titre OctobreRose */}
            <Card bg="warning" className="m-2 border-0">
              <Card.Header
                className="mc-rampart mc-rfs-2 m-3 text-primary rounded-0 text-center bg-warning-subtle"
                style={{ backgroundColor: "rgba(255, 255, 255, 1)" }}
              >
                Journée "Octobre Rose"
              </Card.Header>
            </Card>
            {/************************** Partie Octobre Rose */}
            {photosSorted.map((saison) => {
              return (
                <div key={saison.id}>
                  <Card bg="transparent" className="my-2 border-0">
                    <Card.Text>
                      <span
                        className="mc-marker"
                        style={{
                          top: -(navbarHeight + sideNavHeight + breadNavHeight),
                        }}
                        id={`saison${saison.season}`}
                      ></span>
                    </Card.Text>
                    <Card.Body
                      style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
                      className="m-2 p-2 rounded"
                    >
                      <Card.Header
                        className="mc-rampart mc-rfs-3 m-2 text-primary rounded"
                        style={{ backgroundColor: "rgba(255, 255, 255, 1)" }}
                      >
                        {`SAISON ${saison.season}`}
                      </Card.Header>
                    </Card.Body>
                    <Card.Body
                      style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}
                      className="m-2"
                    >
                      {saison.sessions.map((session, index) => {
                        return (
                          <div key={index}>
                            <Card.Text className="text-dark mc-rfs-4 fw-bold">
                              {session.num === 0
                                ? `Édition du ${session.date} - 
                              ${session.comment}`
                                : `${session.num} doublettes lors de l'édition du ${session.date}`}
                            </Card.Text>
                            <PhotoAlbum layout="rows" photos={session.images} />
                          </div>
                        );
                      })}
                    </Card.Body>
                  </Card>
                </div>
              );
            })}
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default OctobreRose;
