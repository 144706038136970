import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import bonneAnnee from "../../../assets/img/img-news/bonneAnnee2025-450x300.png";

const Reserve = () => {
  return (
    <>
      <Col
        xs={{ span: 10, offset: 1 }}
        md={{ span: 6, offset: 0 }}
        lg={4}
        className="p-4"
      >
        <Card>
          <Card.Img variant="top" src={bonneAnnee} alt="..." />
          <Card.Body>
            <Card.Text>
              <em className="fw-bold">
                Le TENNIS CLUB DE FEILLENS vous souhaite une très bonne année
                2025 !!
              </em>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default Reserve;
