import { Row, Col, Card, Image } from "react-bootstrap";
import { useNavHeight } from "../../../__utils/context/navheightprovider";
import findImage from "../../../__utils/tools/find-image";
import tabEnseignement from "../../../__params/tab-pages/enseignement/tab-enseignement";

const TennisAdultes = () => {
  const { navbarHeight, sideNavHeight, breadNavHeight } = useNavHeight();
  return (
    /***********************************************************/
    /************************** Partie Tennis Adultes  *********/
    /***********************************************************/
    <Card
      className="m-2 border border-4"
      style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
    >
      <span
        className="mc-marker"
        style={{
          top: -(navbarHeight + sideNavHeight + breadNavHeight + 10),
        }}
        id="tennisadulte"
      ></span>
      <Card.Header className="mc-rampart mc-rfs-2 m-2 py-2 px-0 fw-bold text-primary text-center rounded-2 bg-light">
        Tennis Adultes
      </Card.Header>
      <Row>
        <Col md={12} lg={7} className="gx-0">
          <Card bg="transparent" className="m-3 border-0">
            <Card.Body className="mt-2 px-0 py-0">
              <Card.Text>
                1h à une 1h15 par semaine en soirée du lundi au vendredi et de
                septembre à juin (hors vacances scolaires).
                <br />
                Les joueurs et joueuses sont regroupés en fonction de leur
                niveau ou de leur classement.
              </Card.Text>
              <Card.Title className="fw-bold text-primary mb-2">
                Tennis découverte
              </Card.Title>
              <Card.Subtitle className="fw-bold my-2 fst-italic">
                Destiné aux non initiés ou pratiquants occasionnels quel que
                soit leur âge
              </Card.Subtitle>
              <Card.Text>
                L'initiation, encadrée par notre moniteur diplômé, s'oriente
                vers le positionnement des appuis, la maîtrise des trajectoires
                en coup droit et en revers, l'apprentissage de notions tactiques
                et la mise en situation par des jeux permettant de prendre du
                plaisir dès les premières heures de cours.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card bg="transparent" className="m-3 border-0">
            <Card.Body className="mt-2 px-0 py-0">
              <Card.Title className="fw-bold text-primary mb-2">
                Tennis perfectionnement
              </Card.Title>
              <Card.Subtitle className="fw-bold my-2 fst-italic">
                Destiné aux adultes initiés quel que soit leur niveau
              </Card.Subtitle>
              <Card.Text>
                Le tennis perfectionnement, basé sur une pédagogie mise en
                oeuvre par le club et son moniteur diplômé, s'appuie sur un
                enseignement adapté à chaque étape d'apprentissage. Elle permet
                aux joueurs et joueuses, désireux de s'orienter vers davantage
                de compétition ou simplement de continuer à progresser dans un
                environnement convivial, d'améliorer leur maîtrise des
                techniques de bases du tennis (coup droit, revers, volée, smash,
                déplacement, reprise d'appuis, ...).
              </Card.Text>
            </Card.Body>
          </Card>
          <Card bg="transparent" className="m-3 border-0">
            <Card.Body className="mt-2 px-0 py-0">
              <Card.Title className="fw-bold text-primary mb-2">
                Tennis compétition
              </Card.Title>
              <Card.Text>
                Il est réservé aux joueurs et joueuses compétiteurs réguliers.
                Les fondamenteaux technico-tactiques sont acquis chez ces
                joueurs qui cherchent à solidifier tous les secteurs de leur
                jeu. Ils vont renforcer leurs points faibles et faire ressortir
                leurs points forts afin d'optimiser leur tennis.
              </Card.Text>
              <Card.Text>
                À raison d'une séance d'1h15mn par semaine un entraînement
                intensif adapté au niveau de chaque groupe est proposé.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={12} lg={4} className="gx-0">
          <Image
            src={findImage(tabEnseignement, "Adulte1")}
            alt="Photos Adulte?"
            className="m-2 border border-3 rounded-3"
            thumbnail
          />
          <Image
            src={findImage(tabEnseignement, "Adulte2")}
            alt="Photos Adulte?"
            className="m-2 border border-3 rounded-3"
            thumbnail
          />
        </Col>
      </Row>
    </Card>
  );
};

export default TennisAdultes;
