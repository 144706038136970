// most of photos come from https://pixabay.com/fr/
import React from "react";
import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import PublicRoute from "./routepublic/route-public";
import AdminRoute from "../router/routeadmin/route-admin";
import AuthRoute from "../router/auth/route-auth";
import { AuthProvider } from "../__utils/context/authprovider";
import PrivateRoute from "../router/auth/route-private";
import "../assets/css/styles.css";

const App = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/*" element={<PublicRoute />} />
        <Route path="/auth/*" element={<AuthRoute />} />
        <Route
          path="/admin/*"
          element={
            <PrivateRoute>
              <AdminRoute />
            </PrivateRoute>
          }
        />
      </Routes>
    </AuthProvider>
  );
};

export default App;
