import Button from "react-bootstrap/Button";
import { IconContext } from "react-icons";
import FaIcons from "../../assets/icons/faicons";

export const BtnReset = (action) => {
  return (
    <Button
      id="reset"
      variant="white"
      role="button"
      type="reset"
      onClick={action}
      className="px-2 border border-1 border-secondary bg-secondary text-light border-light align-middle"
    >
      <IconContext.Provider value={{ size: "1rem" }}>
        <FaIcons.FaUndoAlt />
      </IconContext.Provider>
    </Button>
  );
};

export default BtnReset;
