import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import PhotoAlbum from "react-photo-album";
import photosTCFaRG from "../../../__params/tab-pages/events/tab-rg2013";
import videoTCFaRG from "../../../assets/video/unJourARolandGarros.mp4";
import MenuBreadcrumbEvents from "../../../components/menus/m-breadcrumb";
import { useNavHeight } from "../../../__utils/context/navheightprovider";
import ReactPlayer from "react-player/lazy";

const EventsRg2013 = () => {
  const [visible, setVisible] = useState(true);
  const { navbarHeight } = useNavHeight();

  const onHandlePlay = () => {
    setVisible(false);
  };

  const onHandlePause = () => {
    setVisible(true);
  };

  return (
    <main role="main" className="mc-fond-rg">
      <Container fluid className="gx-0">
        <Row className="gx-0">
          <Col
            className="gx-0"
            style={{
              position: "sticky",
              top: navbarHeight,
              background: "rgba(0, 43, 110, 0.5)",
              zIndex: "1000",
            }}
          >
            {/***************************** menu breadcrumb */}
            <MenuBreadcrumbEvents />
            {/************************** Partie Events30Ans */}
            <Card bg="primary" className="m-2 border-0">
              <Card.Header className="mc-rampart m-3 text-primary rounded-0 text-center bg-white">
                Le TC FEILLENS joue à Roland GARROS
              </Card.Header>
              <Card.Text className="text-light text-center fw-bold">
                le 29 juin 2013
              </Card.Text>
            </Card>
            <div className="m-3 p-3 border border-5 border-warning mc-fond-rg">
              <Card.Body className="position-relative text-light mc-rfs-1 fw-bold">
                <div
                  style={{
                    paddingTop:
                      "56.25%" /* correspond au format 100/(16/9) ou ratio: 100 / (1280 / 720) */,
                  }}
                >
                  <ReactPlayer
                    style={{ position: "absolute", top: "0" }}
                    url={videoTCFaRG}
                    width="100%"
                    height="100%"
                    controls
                    onPlay={onHandlePlay}
                    onPause={onHandlePause}
                  />
                </div>
                <div className={`${!visible && "mc-invisible"}`}>
                  <Card.Text className="position-absolute top-0 start-50 translate-middle-x mc-rfs-3 fst-italic">
                    Tennis Club de Feillens
                  </Card.Text>
                  <Card.Text className="mc-txt-animated-orange mc-wendy position-absolute top-50 start-50 translate-middle mc-rfs-1 w-75 text-center">
                    Un jour à Roland Garros
                  </Card.Text>
                </div>
              </Card.Body>
              <Card.Footer className="text-center">
                <small className="text-light fw-bold">
                  Le TCF vainqueur du concours "WE ARE TENNIS" - BNP PARIBAS
                </small>
              </Card.Footer>
            </div>
            {/************************** Partie photosTCFaRG */}
            {photosTCFaRG.map((section) => {
              return (
                <Card
                  bg="transparent"
                  className="m-2 border-0"
                  key={section.id}
                >
                  <Card.Body
                    style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}
                    className="m-2 p-4"
                  >
                    <Card.Title className="text-dark mc-rfs-4 fw-bold">
                      {section.title}
                    </Card.Title>
                    <PhotoAlbum layout="rows" photos={section.images} />
                  </Card.Body>
                </Card>
              );
            })}
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default EventsRg2013;
