// Import necessary hooks from React and React Router
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  // Extract the current pathname from the location object
  const { pathname } = useLocation();

  useEffect(() => {
    // Scroll the window to the top whenever the pathname changes
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]); // The effect runs whenever 'pathname' changes

  // The component doesn't render any visible UI, so it returns null
  return null;
};

// Export the ScrollToTop component as the default export
export default ScrollToTop;
