import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SideMenu from "../../components/menus/m-side";
import { useNavHeight } from "../../__utils/context/navheightprovider";

const GestionClub = () => {
  const { navbarHeight } = useNavHeight();

  return (
    <div>
      <Container fluid className="gx-0">
        <Row className="gx-0">
          <Col
            xs={12}
            md={3}
            className="gx-0"
            style={{
              position: "sticky",
              top: navbarHeight,
              zIndex: "1000",
            }}
          >
            <SideMenu />
          </Col>
          <Col xs={12} md={9} className="mc-page-logo vh-100 bg-primary-subtle">
            <img
              src="logoTCF1.png"
              className="mc-position-logo"
              alt="LogoTCF"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GestionClub;
