import Modal from "react-bootstrap/Modal";
import PdfViewer from "../../../__utils/tools/pdf-viewer";
import pdfFile from "../../../assets/img/Comite-jumelage-choucroute-2025.pdf";

export const ModalPdf = ({ showPdf, setShowPdf, title }) => {
  const handleClose = () => setShowPdf(false);

  return (
    <Modal
      show={showPdf}
      onHide={handleClose}
      centered
      animation
      dialogClassName="text-light"
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        className="border border-info border-3 border-bottom-0 rounded-top"
        style={{ backgroundColor: "rgb(0, 43, 110)" }}
      >
        <Modal.Title className="text-center w-100">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="border border-info border-3 border-bottom-0 border-top-0 px-5 fw-light"
        style={{ backgroundColor: "rgb(0, 43, 110)" }}
      >
        <PdfViewer pdfFile={pdfFile} />
      </Modal.Body>
      <Modal.Footer
        className="border border-info border-3 border-top-0"
        style={{ backgroundColor: "rgb(0, 43, 110)" }}
      ></Modal.Footer>
    </Modal>
  );
};

export default ModalPdf;
