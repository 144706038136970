import { Row, Col, Card, ListGroup, ListGroupItem } from "react-bootstrap";
import { motion } from "framer-motion";
import { useNavHeight } from "../../../__utils/context/navheightprovider";
import findImage from "../../../__utils/tools/find-image";
import tabEnseignement from "../../../__params/tab-pages/enseignement/tab-enseignement";
import ImgMagnifier from "../../../__utils/tools/image-magnifier";

const imageVariants = {
  hover: {
    scale: 0.95,
    transition: { duration: 0.5 },
  },
};

const EcoleTennis = () => {
  const { navbarHeight, sideNavHeight, breadNavHeight } = useNavHeight();
  return (
    /***********************************************************/
    /************************** Partie Ecole de Tennis *********/
    /***********************************************************/
    <Card
      className="m-2 border border-4"
      style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
    >
      <span
        className="mc-marker"
        style={{
          top: -(navbarHeight + sideNavHeight + breadNavHeight + 10),
        }}
        id="ecoletennis"
      ></span>
      <Card.Header className="mc-rampart mc-rfs-2 m-2 py-2 px-0 fw-bold text-primary text-center rounded-2 bg-light">
        École de tennis
      </Card.Header>
      <Card.Body className="mt-2 pb-0">
        <Card.Title className="fw-bold text-primary mb-2">
          Galaxie Tennis de 3 à 5 ans
        </Card.Title>
        <Card.Text>
          Le programme Galaxie 3-5 ans, permet à l'enfant, grâce à un
          enseignement adapté, de s'initier au tennis tout en développant ses
          qualités psychomotrices.
        </Card.Text>
      </Card.Body>
      <Card bg="transparent" className="mx-3 border-0">
        <Card.Body className="mt-2 px-0 py-0">
          <Card.Subtitle className="fw-bold my-2">
            Les premiers pas dans Galaxie Tennis
          </Card.Subtitle>
        </Card.Body>
        <Row>
          <Col md={12} lg={7}>
            <Card.Body className="px-0">
              <Card.Text className="pe-2">
                Ce programme répond aux besoins des enfants de 3 à 5 ans de
                bouger, de s’amuser et... d’apprendre ! Grâce à un langage
                adapté, l'enseignant entre dans l'univers de votre enfant et lui
                permet d'acquérir ses premières empreintes techniques.
                L'utilisation d'outils pédagogiques ludiques et variés colorent
                l’espace de jeu et contribuent à l'aspect récréatif de
                l'activité.
                <br />
                <br />
                Sous l’œil bienveillant de l’enseignant, l’enfant expérimente de
                nouvelles situations et développe les qualités nécessaires à sa
                future vie d’adulte : persévérance, confiance, goût de l’effort,
                respect des autres... tout en tapant ses premières balles !
              </Card.Text>
            </Card.Body>
          </Col>
          <Col md={12} lg={5}>
            <Card.Img
              src={findImage(tabEnseignement, "Galaxie")}
              alt="Photo Galaxie?"
              className="rounded-3"
            />
          </Col>
        </Row>
      </Card>
      <Card bg="transparent" className="mx-3 border-0 rounded-0">
        <Card.Body className="px-0">
          <Card.Subtitle className="fw-bold my-2">
            Déroulement d'une séance
          </Card.Subtitle>
          <Card.Text>
            Un matériel adapté délimite des espaces de jeu où des ateliers sont
            mis en place. Ainsi, les enfants évoluent d'un atelier à l'autre par
            petits groupes en toute sécurité. Ils pénètrent ainsi dans un
            univers de jeu, se familiarisent avec la raquette, les ballons, les
            balles, les cibles, expérimentent, apprennent et se sociabilisent.
            En tant que parents, vous pouvez simplement accompagner votre enfant
            ou bien participer à la séance au sein d’un atelier. Galaxie 3 - 5
            ans c’est aussi partager des moments forts avec son enfant !
          </Card.Text>
          <Card.Text className="fw-bold">
            Vous souhaitez inscrire votre enfant au tennis ?{" "}
          </Card.Text>
          <Card.Text>
            n'hésitez pas à nous{" "}
            <a className="text-muted fw-bold" href="/contact">
              <span>contacter</span>
            </a>{" "}
            pour de plus amples informations.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card bg="transparent" className="mx-3 border-0 rounded-0">
        <Card.Body className="px-0">
          <Card.Title className="fw-bold text-primary mb-2">
            Galaxie Tennis de 6 à 10 ans
          </Card.Title>
          <Card.Text>
            Le programme Galaxie Tennis, place le jeu au coeur du dispositif
            d'apprentissage et a pour objectif d’amener les enfants à jouer
            dedans le respect des valeurs du sport.
          </Card.Text>
        </Card.Body>
        <Row>
          <Col xs={12} lg={4} className="gx-0">
            <motion.div
              className="m-2"
              variants={imageVariants}
              whileHover="hover"
            >
              <ImgMagnifier
                shape={"rectangle"}
                width={"100%"}
                src={findImage(tabEnseignement, "AfficheGalaxie")}
              />
            </motion.div>
            <motion.div
              className="m-2"
              variants={imageVariants}
              whileHover="hover"
            >
              <ImgMagnifier
                shape={"rectangle"}
                width={"100%"}
                src={findImage(tabEnseignement, "CriteresGalaxie")}
              />
            </motion.div>
          </Col>
          <Col xs={12} lg={8}>
            <Card.Body className="px-0">
              <Card.Subtitle className="fw-bold my-2">
                Le jeu avant tout
              </Card.Subtitle>
              <Card.Text>
                Pédagogie, matériel et terrains : tout est fait pour permettre
                aux enfants de jouer dès les premières séances. Ils s'amusent,
                se dépensent et progressent tout en échangeant la balle ou en
                tentant de gagner des points.
                <br />
                <br />
                Ils apprennent ainsi à analyser la situation, à s’adapter à
                différents styles de jeu et à forger leur propre jeu.
                <br />
                <br />
                L’enfant développe de concert ses qualités physiques et
                psychologiques : vitesse, endurance, concentration, agilité,
                coordination, adaptation, adresse, contrôle de soi...
              </Card.Text>
            </Card.Body>
            <Card.Body className="px-0">
              <Card.Subtitle className="fw-bold my-2">
                5 niveaux d'apprentissage
              </Card.Subtitle>
              <Card.Text>
                Cinq niveaux permettent à l'enseignant de faire évoluer
                progressivement les enfants du mini court au court "des grands"
                en évaluant régulièrement leurs compétences dans les journées «
                Jeu et Match » :
              </Card.Text>
              <ListGroup numbered className="mc-rfs-4">
                <ListGroupItem className="border-0 bg-light">
                  <span className="fw-bold bg-dark text-light">
                    Terrain blanc
                  </span>{" "}
                  : 8 m de long sans filet avec ballon blanc (20 cm de diamètre)
                </ListGroupItem>
                <ListGroupItem className="border-0 bg-light">
                  <span
                    className="fw-bold text-light"
                    style={{ backgroundColor: "purple" }}
                  >
                    Terrain violet
                  </span>{" "}
                  : 11 m avec filet à 0,50 m et une balle violette (12 à 15 cm
                  de diamètre)
                </ListGroupItem>
                <ListGroupItem className="border-0 bg-light">
                  <span
                    className="fw-bold text-light"
                    style={{ backgroundColor: "red" }}
                  >
                    Terrain rouge
                  </span>{" "}
                  : 12,8 m avec filet de 0,80 m et balle rouge (7,5 cm de
                  diamètre)
                </ListGroupItem>
                <ListGroupItem className="border-0 bg-light">
                  <span
                    className="fw-bold text-light"
                    style={{ backgroundColor: "orange" }}
                  >
                    Terrain orange
                  </span>{" "}
                  : 18 m avec filet à 0,80 m et balle orange
                </ListGroupItem>
                <ListGroupItem className="border-0 bg-light">
                  <span
                    className="fw-bold text-light"
                    style={{ backgroundColor: "green" }}
                  >
                    Terrain vert
                  </span>{" "}
                  : Court de tennis traditionnel avec balle verte
                </ListGroupItem>
              </ListGroup>
            </Card.Body>
            <Card.Body className="px-0">
              <Card.Subtitle className="fw-bold my-2">
                Les valeurs par le sport
              </Card.Subtitle>
              <Card.Text>
                Il n'y a pas de sport sans esprit sportif. Le respect des règles
                du jeu, de l’adversaire, de l’enseignant, de l’arbitre, le
                fair­play, l’honnêteté, la maîtrise de soi dans la victoire
                comme dans la défaite : ces valeurs du tennis dessinent un cadre
                autour de l'activité des enfants pour qu'ils progressent tout en
                prenant un maximum de plaisir sur le court. Les enseignants et
                les bénévoles s’attachent également à véhiculer cet état
                d’esprit au sein du club. Ainsi, l’environnement est propice à
                l'intégration des enfants dans la vie sportive du club. Les
                séances d’enseignement hebdomadaires et les journées « Jeu &
                Match » favorisent les échanges entre les enfants... sur le
                court comme en-dehors des courts !
              </Card.Text>
            </Card.Body>
          </Col>
        </Row>
        <Card.Body className="px-0">
          <Card.Subtitle className="fw-bold">Objectif match !</Card.Subtitle>
          <Card.Text className="my-2">
            L’objectif de Galaxie Tennis est d’amener tout naturellement les
            enfants à faire des matchs en autonomie. Des initiations aux matchs,
            des animations ou « plateaux » (sans officialisation de résultat) et
            des compétitions officielles (à partir de 8 ans et le niveau orange)
            sont organisées dans le club tout au long de l’apprentissage pour
            créer une atmosphère de compétition saine et motiver les
            enfants...car le jeu c’est le match !
          </Card.Text>
        </Card.Body>

        <Card.Body className="px-0">
          <Card.Subtitle className="fw-bold my-2">
            Vous souhaitez inscrire votre enfant au tennis ?{" "}
          </Card.Subtitle>

          <Card.Text>
            n'hésitez pas à nous{" "}
            <a className="text-muted fw-bold" href="/contact">
              <span>contacter</span>
            </a>{" "}
            pour de plus amples informations.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card bg="transparent" className="mx-3 border-0 runded-0">
        <Card.Body className="px-0">
          <Card.Title className="fw-bold text-primary mb-2">
            Le tennis ADOS de 11 à 16 ans
          </Card.Title>
          <Card.Text>
            La pédagogie du programme Ados s'adapte au rythme et aux attentes
            des adolescents afin de favoriser leur progression et leur
            investissement.
            <br />
            Un rythme adapté
            <br />
            Les adolescents sont formés selon{" "}
            <span className="fw-bold text-danger">
              un programme annuel
            </span>{" "}
            basé sur des séances hebdomadaires de 2h30 encadrées par notre
            enseignant.
            <br />
            En période de vacances scolaires, des stages peuvent leur permettre
            d’accélérer leurs progrès grâce à une pratique plus intensive.
          </Card.Text>
        </Card.Body>
        <Row>
          <Col md={12} lg={6}>
            <Card.Body className="px-0">
              <Card.Subtitle className="fw-bold">
                Gagner en autonomie
              </Card.Subtitle>
              <Card.Text>
                En parallèle de ces séances, l’encadrement du club encourage le
                jeu libre en aidant les adolescents à s’organiser, planifier des
                matchs entre eux et réserver leurs terrains eux-mêmes. Pour
                faciliter cette prise d’autonomie, certains créneaux horaires
                leur sont par exemple réservés en journée pendant les vacances
                scolaires. Des séances « Jeu & Matchs » sont également
                organisées pour évaluer les progrès de chacun et fixer tous ses
                prochains objectifs.
              </Card.Text>
            </Card.Body>
            <Card.Body className="px-0">
              <Card.Subtitle className="fw-bold">
                Dans la vie du club
              </Card.Subtitle>
              <Card.Text>
                Un des objectifs essentiel du programme ADOS est de valoriser la
                place des adolescents au sein du club : en leur confiant des
                missions lors d’événements réunissant l’ensemble des adhérents
                du club (tournois, rencontres par équipes, animations diverses),
                en les orientant vers des formations d’arbitrage ou
                d’initiateur, en les aidant à créer une commission ADOS au sein
                du club. Le club favorisera leur intégration, et surtout, leur
                épanouissement sportif comme humain.
              </Card.Text>
            </Card.Body>
          </Col>
          <Col md={12} lg={6} className="gx-0">
            <Card.Img
              src={findImage(tabEnseignement, "Ados")}
              alt="Photos Ados?"
              className="m-2 border border-3 rounded-3"
            />
          </Col>
        </Row>
        <Card.Body className="px-0">
          <Card.Subtitle className="fw-bold">
            Vous souhaitez inscrire votre enfant au tennis ?{" "}
          </Card.Subtitle>
          <Card.Text>
            n'hésitez pas à nous{" "}
            <a className="text-muted fw-bold" href="/contact">
              <span>contacter</span>
            </a>{" "}
            pour de plus amples informations.
          </Card.Text>
        </Card.Body>
      </Card>
    </Card>
  );
};

export default EcoleTennis;
