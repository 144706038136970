import Modal from "react-bootstrap/Modal";
import image from "../../../assets/img/img-news/pickleball-202502-1238x1750.jpg";

export const ModalImg = ({ showImg, setShowImg, title }) => {
  const handleClose = () => setShowImg(false);

  return (
    <Modal
      show={showImg}
      onHide={handleClose}
      centered
      animation
      dialogClassName="text-light"
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        className="border border-info border-3 border-bottom-0 rounded-top"
        style={{ backgroundColor: "rgb(0, 43, 110)" }}
      >
        <Modal.Title className="text-center w-100">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="border border-info border-3 border-bottom-0 border-top-0 px-5 fw-light"
        style={{ backgroundColor: "rgb(0, 43, 110)" }}
      >
        <img src={image} class="img-fluid" alt="..." />
      </Modal.Body>
      <Modal.Footer
        className="border border-info border-3 border-top-0"
        style={{ backgroundColor: "rgb(0, 43, 110)" }}
      ></Modal.Footer>
    </Modal>
  );
};

export default ModalImg;
