import React, { useState } from "react";
import { motion } from "framer-motion";
import Col from "react-bootstrap/Col";
import Carousel from "react-bootstrap/Carousel";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import tableNews from "../../../__params/tab-pages/home/tab-news";
// import choucroutePdf from "../../../assets/img/Comite-jumelage-choucroute-2025.pdf";
import ModalPdf from "./c-modal-pdf";
import ModalImg from "./c-modal-img";

const News = () => {
  const [showOrder, setShowOrder] = useState(false);
  const [showImg, setShowImg] = useState(false);

  const imageVariants = {
    hover: {
      scale: 0.95,
      transition: { duration: 0.5 },
    },
  };

  const handleModal = (title) => {
    if (title === "Choucroute 2025") return () => setShowOrder(true);
    if (title === "Pickleball 2025") return () => setShowImg(true);
  };

  return (
    <>
      <Col xs={12} md={{ span: 7, offset: 0 }} lg={5} className="p-4">
        <Carousel fade className="text-light">
          {tableNews.map((news) => (
            <Carousel.Item key={news.id}>
              {news.title === "Choucroute 2025" ||
              news.title === "Pickleball 2025" ? (
                <>
                  <div onClick={handleModal(news.title)}>
                    <OverlayTrigger
                      className="bg-dark text-light"
                      placement="top"
                      overlay={
                        <Tooltip>Cliquez pour télécharger le document</Tooltip>
                      }
                    >
                      <motion.div
                        className=""
                        variants={imageVariants}
                        whileHover="hover"
                      >
                        <img
                          className="w-100 d-block border border-2 rounded-3"
                          src={news.img}
                          alt="Missing slide"
                        />
                      </motion.div>
                    </OverlayTrigger>
                  </div>
                  {news.title === "Choucroute 2025" ? (
                    <ModalPdf
                      showPdf={showOrder}
                      setShowPdf={setShowOrder}
                      title={news.title}
                    />
                  ) : (
                    <ModalImg
                      showImg={showImg}
                      setShowImg={setShowImg}
                      title={news.title}
                    />
                  )}
                  <div
                    className="border p-2 border-top-0 border-2 rounded-3"
                    style={{ backgroundColor: "#595959" }}
                  >
                    <h5>{news.title}</h5>
                    <p>
                      {news.texte}{" "}
                      <span className="fw-bold mc-rfs-4">{news.texteGras}</span>
                    </p>

                    <br />
                  </div>
                </>
              ) : (
                <>
                  <a href={news.link}>
                    <motion.div
                      className=""
                      variants={imageVariants}
                      whileHover="hover"
                    >
                      <img
                        className="w-100 d-block border border-2 rounded-3"
                        src={news.img}
                        alt="Missing slide"
                      />
                    </motion.div>
                  </a>
                  <div
                    className="border p-2 border-top-0 border-2 rounded-3"
                    style={{ backgroundColor: "#595959" }}
                  >
                    <h5>{news.title}</h5>
                    <p>
                      {news.texte}{" "}
                      <span className="fw-bold mc-rfs-4">{news.texteGras}</span>
                    </p>
                    <br />
                  </div>
                </>
              )}
            </Carousel.Item>
          ))}
        </Carousel>
      </Col>
    </>
  );
};

export default News;
