import { motion } from "framer-motion";
import { Card, Nav } from "react-bootstrap";
import { useNavHeight } from "../../../__utils/context/navheightprovider";
import findImage from "../../../__utils/tools/find-image";
import tabEnseignement from "../../../__params/tab-pages/enseignement/tab-enseignement";
import { IconContext } from "react-icons";
import { FaChevronRight } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const buttonVariants = {
  hover: {
    // scale: [1, 1.2, 1, 1.2, 1, 1.2, 1, 1.2, 1, 1.2, 1],
    scale: 1.3,
    textShadow: "0 0 8px rgb(255, 255, 255)",
    transition: { duration: 0.5, repeat: Infinity },
  },
};

const StageTennis = () => {
  const { navbarHeight, sideNavHeight, breadNavHeight } = useNavHeight();
  return (
    /***********************************************************/
    /************************** Partie Stages de Tennis ********/
    /***********************************************************/
    <Card
      className="m-2 border border-4"
      style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
    >
      <span
        className="mc-marker"
        style={{
          top: -(navbarHeight + sideNavHeight + breadNavHeight + 10),
        }}
        id="stagetennis"
      ></span>
      <Card.Header className="m-2 py-2 px-0 fw-bold text-primary text-center rounded-2 bg-light">
        <Card.Text className="mc-rampart mc-rfs-2 m-0">
          Stages de Tennis
        </Card.Text>
        <Nav className="mc-rfs-4 position-absolute top-0 end-0 mt-3 me-4">
          <NavLink
            className="p-1 px-2 border border-2 border-muted bg-primary rounded text-center nav-link"
            to="/enseignement/stages"
          >
            <motion.div
              className="text-light fw-semibold"
              variants={buttonVariants}
              whileHover="hover"
            >
              <span className="pe-2 align-middle">Voir</span>
              <IconContext.Provider value={{ size: "1em" }}>
                <FaChevronRight />
              </IconContext.Provider>
            </motion.div>
          </NavLink>
        </Nav>
      </Card.Header>
      <Card
        bg="transparent"
        className="flex-lg-row justify-content-between border-0"
      >
        <Card.Body>
          <Card.Img
            src={findImage(tabEnseignement, "StageP1")}
            alt="Photo Stage?"
            className="rounded-3"
          />
        </Card.Body>
        <Card.Body>
          <Card.Img
            src={findImage(tabEnseignement, "StageP2")}
            alt="Photo Stage?"
            className="rounded-3"
          />
        </Card.Body>
      </Card>
    </Card>
  );
};

export default StageTennis;
