import { Row, Col, Card } from "react-bootstrap";
import { useNavHeight } from "../../../__utils/context/navheightprovider";
import findImage from "../../../__utils/tools/find-image";
import tabEnseignement from "../../../__params/tab-pages/enseignement/tab-enseignement";

const EquipePedagogique = () => {
  const { navbarHeight, sideNavHeight, breadNavHeight } = useNavHeight();
  return (
    /***********************************************************/
    /************************** Partie Equipe Pédagogique ******/
    /***********************************************************/
    <Card
      className="m-2 border border-4"
      style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
    >
      <span
        className="mc-marker"
        style={{
          top: -(navbarHeight + sideNavHeight + breadNavHeight + 10),
        }}
        id="equipepedagogique"
      ></span>
      <Card.Header className="mc-rampart mc-rfs-2 m-2 py-2 px-0 fw-bold text-primary text-center rounded-2 bg-light">
        Équipe Pédagogique
      </Card.Header>
      <Row>
        <Col md={3}>
          <Card.Img
            style={{ maxWidth: "130px", minWidth: "100px" }}
            src={findImage(tabEnseignement, "Fred")}
            alt="Photo Id?"
            className="mx-3 rounded-3"
          />
        </Col>
        <Col md={9}>
          <Card.Body className="mx-2">
            <Card.Text className="mc-rfs-3 fw-bold">Frédéric GAUVRIT</Card.Text>
            <Card.Text className="text-primary fw-bold mc-rfs-4">
              <span>Moniteur Diplômé d'état</span>
              <br />
              <span>Formation Tennis Santé / Bien-être</span>
            </Card.Text>
            <Card.Text className="fw-bold mc-rfs-4">06 60 61 21 53</Card.Text>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
};

export default EquipePedagogique;
